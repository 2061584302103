import produce from "immer";
import isEmpty from "lodash/isEmpty";

import {
  BarsOutlined,
  CloseOutlined,
  DownOutlined,
  PlusOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { horizontalListSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Popover, Select, Tag } from "antd";
import { useState } from "react";

import { fieldTypes } from "@evolved/constants";

import { useViewStore } from "../../stores/view";
import { filterOption } from "../selects/filter-option";
import { SortableList } from "../sortable-list";
import { getFieldList } from "./get-field-list";
import { useFieldConfigs } from "./use-field-configs";
import { isFieldSortable } from "@evolved/domain";

const orderStyle = { cursor: "pointer", fontSize: "10px", color: "grey" };

const SortableItem = (props) => {
  const { id, label, onSort, order, removeField } = props;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  return (
    <li
      ref={setNodeRef}
      style={{
        alignItems: "center",
        display: "flex",
        listStyle: "none",
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <Tag style={{ margin: "4px 4px 4px 0px" }}>
        <div style={{ alignItems: "center", display: "flex" }}>
          <BarsOutlined
            {...attributes}
            {...listeners}
            style={{ cursor: "move", marginRight: "8px" }}
          />
          {order === "descend" ? (
            <DownOutlined onClick={onSort} style={orderStyle} />
          ) : (
            <UpOutlined onClick={onSort} style={orderStyle} />
          )}
          <div style={{ marginLeft: "4px" }}>{label}</div>
          <CloseOutlined
            onClick={removeField}
            style={{ cursor: "pointer", marginLeft: "8px" }}
          />
        </div>
      </Tag>
    </li>
  );
};

const getAvailableSortOrders = ({
  blacklist,
  fields,
  fieldConfigs,
  sortOrder,
}) => {
  return getFieldList(fieldConfigs).filter(
    (f) =>
      isFieldSortable(f) &&
      fields.includes(f.dataIndex) &&
      !sortOrder.some(({ dataIndex }) => dataIndex === f.dataIndex) &&
      !blacklist.includes(f.dataIndex)
  );
};

export const Sorters = (props) => {
  const { blacklist = [], viewType } = props;

  const fields = useViewStore((state) => state[viewType].fields);
  const sortOrder = useViewStore((state) => state[viewType].sortOrder);

  const fieldConfigs = useFieldConfigs(viewType);

  const [isVisible, setIsVisible] = useState();

  const sortOptions = getAvailableSortOrders({
    blacklist,
    fields,
    fieldConfigs,
    sortOrder,
  });

  return (
    <>
      <div
        style={{
          fontSize: "18px",
          color: "grey",
          marginRight: "8px",
        }}
      >
        |
      </div>
      <div
        style={{
          color: "grey",
          marginRight: "8px",
        }}
      >
        Sort By:
      </div>
      <SortableList
        items={sortOrder
          .filter(({ dataIndex }) => fieldConfigs[dataIndex])
          .map(({ dataIndex, order }) => ({
            id: dataIndex,
            Component: SortableItem,
            props: {
              label: fieldConfigs[dataIndex].title,
              onSort: () =>
                useViewStore.getState().toggleSortOrder(viewType)(dataIndex),
              order,
              removeField: () =>
                useViewStore.getState().removeSortOrder(viewType)(dataIndex),
            },
          }))}
        strategy={horizontalListSortingStrategy}
        setItems={(dataIndexes) => {
          useViewStore.getState().setSortOrder(viewType)(
            dataIndexes.map((dataIndex) =>
              sortOrder.find((sortOrder) => sortOrder.dataIndex === dataIndex)
            )
          );
        }}
      />
      {!isEmpty(sortOptions) && sortOrder.length < 3 && (
        <Popover
          content={
            <>
              <Select
                autoFocus
                filterOption={filterOption}
                onChange={(dataIndex) => {
                  useViewStore.getState().setSortOrder(viewType)(
                    produce(sortOrder, (draft) => {
                      draft.push({ dataIndex, order: "ascend" });
                    })
                  );
                  setIsVisible(false);
                }}
                open
                options={sortOptions.map(({ dataIndex, title }) => ({
                  value: dataIndex,
                  label: title,
                }))}
                optionFilterProp="children"
                showSearch
                size="small"
                style={{ marginBottom: "8px", width: "100%" }}
              />
            </>
          }
          destroyTooltipOnHide
          onOpenChange={setIsVisible}
          open={isVisible}
          overlayStyle={{ maxWidth: "250px", width: "250px" }}
          placement="bottom"
          trigger="click"
        >
          <Button
            icon={<PlusOutlined />}
            onClick={() => setIsVisible(!isVisible)}
            size="small"
            style={{ marginRight: "8px" }}
          >
            Sort
          </Button>
        </Popover>
      )}
    </>
  );
};
