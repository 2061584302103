// @ts-check

import React from "react";
import { saveAs } from "file-saver";

import { reduceFieldsToMap } from "@evolved/views/src/reduce-fields-to-map";

import { DownloadCSV } from "../../view/download-csv/download-csv";

/** @typedef {import("./domain").ImportableEntityTypes} Types

/** @type {Record<Types, string>} */
const FILE_NAMES = {
  ACCOUNT: "accounts-import-template.csv",
  OPPORTUNITY: "opportunities-import-template.csv",
  VENDOR: "vendors-import-template.csv",
  CONTACT: "contacts-import-template.csv",
};

/** @type {Partial<Record<Types, JSX.Element>>} */
const EXPLAINER_TEXT = {
  "OPPORTUNITY": (
    <span>
      Opportunity <strong>Progress</strong> and <strong>Stage</strong>{" "}
      are CRM system fields handled by completion of sales process steps. However, you can
      set the <strong>Initial Progress</strong> and <strong>Initial Stage</strong> fields to set the initial values. This is useful if you are migrating from another system.
    </span>
  ),
};

/**
 * @param {Object} o
 * @param {import("./domain").ImportableEntityTypes} o.entityType
 * @param {import("./domain").ImportField[]} o.importFields
 * @param {(error: Error) => void} o.onError
 */
const onClick =
  ({ entityType, importFields, onError }) =>
    async () => {
      try {
        const file = new File(
          [importFields.map(({ name }) => name).join()],
          FILE_NAMES[entityType],
          { type: "text/plain;charset=utf-8" }
        );

        saveAs(file);
      } catch (err) {
        onError(new Error("Something bad happened and it's not your fault"));
      }
    };

/**
 * @param {Object} o
 * @param {import("./domain").ImportableEntityTypes} o.entityType
 * @param {import("./domain").ImportField[]} o.importFields
 * @param {(error: Error) => void} o.onError
 */
export const GetTemplate = ({ onError, entityType, importFields }) => {
  return (
    <div>
      Adding new records?{" "}
      <span
        className="primary-clickable font-semibold"
        onClick={onClick({
          onError,
          entityType,
          importFields,
        })}
      >
        Download the import template
      </span>
      {" "}to get started.
      <br />
      <br />
      Updating existing records? <DownloadCSV
        fieldConfigsOverride={reduceFieldsToMap(importFields)}
        fieldsOverride={importFields.map(({ dataIndex }) => dataIndex)}
        asText={"Export your existing data"}
        viewType={entityType}
      /> and upload to apply any changes.
      <br />
      <br />
      If you have any questions, please check out our{" "}
      <a
        className="primary-clickable"
        href="https://www.youtube.com/channel/UC7gblyuosT1dJJLU4zl8oUg"
        target="_blank"
        rel="noopener noreferrer"
      >
        YouTube channel
      </a>{" "}for help videos.
      <br />
      <br />
      {EXPLAINER_TEXT[entityType] && (
        <>
          <div>Note: {EXPLAINER_TEXT[entityType]}</div>
          <br />
          <br />
        </>
      )}
    </div>
  );
};
