// @ts-check

import { areHeadersEqual } from "./are-headers-equal";

/** @typedef {(string | null)[]} Result */

/**
 * Get default header data indexes.
 *
 * Should always return an array that is of the same
 * length as the input.
 *
 * Assumptions:
 *
 * - duplicate headers have been removed
 *
 * @param {Object} o
 * @param {import("./domain").ImportField[]} o.importFields
 * @param {string[]} o.headers
 *
 * @returns {Result}
 */
export const getDefaultHeaderDataIndexes = ({ importFields, headers }) => {
  /**
   * @param {Result} acc
   * @param {string} header
   *
   * @returns {Result}
   */
  const reducer = (acc, header) => {
    const available = importFields.filter(({ dataIndex }) => {
      return !acc.some((_dataIndex) => _dataIndex === dataIndex);
    });

    const match = available.find((fieldConfig) => {
      return areHeadersEqual(fieldConfig.name, header);
    });

    if (match) {
      acc.push(match.dataIndex);
    } else {
      acc.push(null);
    }

    return acc;
  };

  return headers.reduce(reducer, []);
};
