// @ts-check

export const ACCOUNT_STATE = "ACCOUNT_STATE";
export const ACTIVITY_DATE = "ACTIVITY_DATE";
export const CALCULATED = "CALCULATED";
export const DATE = "DATE";
export const DOLLAR = "DOLLAR";
export const FOLLOWUP_DATE = "FOLLOWUP_DATE";
export const LINK = "LINK";
export const NUMBER = "NUMBER";
export const PERCENT = "PERCENT";
export const RANGE = "RANGE";
export const SELECT = "SELECT";
export const SET = "SET";
export const TEXT = "TEXT";

export const DEFAULT_WIDTHS = {
  [ACCOUNT_STATE]: 100,
  [ACTIVITY_DATE]: 130,
  [CALCULATED]: 130,
  [DATE]: 130,
  [DOLLAR]: 150,
  [FOLLOWUP_DATE]: 175,
  [LINK]: 150,
  [NUMBER]: 130,
  [PERCENT]: 150,
  [RANGE]: 150,
  [SELECT]: 250,
  [SET]: 250,
  [TEXT]: 250,
};
