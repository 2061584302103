// @ts-check

import { Button, Select } from "antd";
import React, { useState } from "react";
import { fieldTypes } from "@evolved/constants";

import { reduceColumnOptions } from "./reduce-column-options";
import { CREATE_USER_DEFINED_FIELD_OPTION } from "./identify-headers";

/** @satisfies {Readonly<import("@evolved/domain").UserDefinedFieldType[]>} */
export const CREATABLE_FIELD_TYPES = Object.freeze([
  "SELECT",
  "TEXT",
  "DATE",
  "NUMBER",
]);
/**
 * @typedef {typeof CREATABLE_FIELD_TYPES[number]} CreatableFieldType
 */

/** 
 * @typedef {Object} Props
 *
 * @prop {import("./domain").CSVUpload} upload
 * @prop {(string | null)[]} headerDataIndexes
 * @prop {import("@evolved/domain").EntityType} entityType
 * @prop {(import("@evolved/domain").UserDefinedField | null)[]} [newUserDefinedFields]
 *
 * @prop {() => void} onBack
 * @prop {(update: (import("@evolved/domain").UserDefinedField | null)[]) => void} onNext
 */

/**
 * @type {React.FC<Props>}
 */
export const ConfigureUdfs = ({ upload, headerDataIndexes, entityType, newUserDefinedFields, onBack, onNext }) => {
  /** @type{(null | CreatableFieldType )[]}  */
  const defaults =
    headerDataIndexes.map((dataIndex, index) => {
      if (dataIndex !== CREATE_USER_DEFINED_FIELD_OPTION.value) {
        return null;
      }

      const currentDataType = newUserDefinedFields?.[index]?.dataType;

      // NOTE: CALCULATED is not currently supported
      if (currentDataType === "CALCULATED") {
        return "TEXT";
      }

      return currentDataType ?? "TEXT";
    });

  /** @type {import("@evolved/domain").UseState<(null | CreatableFieldType )[]>} */
  const [types, setTypes] = useState(
    defaults
  );

  const onSubmit = () => {
    /** @type {(import("@evolved/domain").UserDefinedField | null)[]} */
    const newFields = headerDataIndexes.map((dataIndex, index) => {
      if (dataIndex !== CREATE_USER_DEFINED_FIELD_OPTION.value) {
        return null;
      }

      const type = types[index];
      if (!type) {
        throw new Error(`Type should have been selected for column ${index + 1}`);
      }

      /** @type {import("@evolved/domain").UserDefinedField} */
      let field;

      if (type === "SELECT") {
        field = {
          id: crypto.randomUUID(),
          dataType: type,
          type: entityType,
          name: upload.headers[index],
          options: reduceColumnOptions({
            generateId: () => crypto.randomUUID(),
            index,
            rows: upload.rows,
          }),
        }
      } else {
        field = {
          id: crypto.randomUUID(),
          dataType: type,
          type: entityType,
          name: upload.headers[index],
        }
      }

      return field;
    });

    onNext(newFields);
  };

  return (
    <>
      <div className="mb-4 flex font-bold">
        <div>Name</div>
        <div className="ml-auto">Create</div>
      </div>
      {headerDataIndexes.map((dataIndex, index) => {
        if (dataIndex !== CREATE_USER_DEFINED_FIELD_OPTION.value) {
          return null;
        }

        return (
          <div className="mb-4 flex" key={dataIndex}>
            <div>{upload.headers[index]}</div>
            <div className="ml-auto flex items-start space-x-4">
              <div>
                <Select
                  value={types[index]}
                  onChange={(value) => {
                    const next = [...types];
                    next.splice(index, 1, value);

                    setTypes(next);
                  }}
                  options={[
                    {
                      value: fieldTypes.TEXT,
                      label: "Text",
                    },
                    {
                      value: fieldTypes.SELECT,
                      label: "Select",
                    },
                    {
                      value: fieldTypes.DATE,
                      label: "Date",
                    },
                  ]}
                  style={{ width: "120px" }}
                />
              </div>
            </div>
          </div>
        );
      })}
      <div className="flex justify-end">
        <Button className="mr-2" onClick={onBack}>Back</Button>
        <Button type="primary" onClick={onSubmit}
        >Next</Button>
      </div>
    </>
  );
};
