// @ts-check

import get from "lodash/get";

import { normalize } from "./are-headers-equal";

/**
 * @param {Object} o
 * @param {object} o.entity
 * @param {string[]} o.dataIndexes
 *
 * @returns {string}
 */

export const getEntityMatchKey = ({ entity, dataIndexes }) => {
  return dataIndexes
    .map((dataIndex) => get(entity, dataIndex))
    .filter((value) => !!value)
    .map(normalize)
    .join(" ");
};

/**
 * @param {Object} o
 * @param {object[]} o.entities
 * @param {string[]} o.dataIndexes - dataIndex to match on
 *
 * @returns {Record<string, string[]>}
 */
export const reduceMatches = ({ entities, dataIndexes }) => {
  /**
   * @param {Record<string, string[]>}  acc
   * @param {object} entity
   *
   * @returns {Record<string, string[]>}
   */
  const reducer = (acc, entity) => {
    const key = getEntityMatchKey({ entity, dataIndexes });
    if (!key) {
      return acc;
    }

    if (!acc[key]) {
      acc;
    }

    const id = entity._id;
    if (id === null || id === undefined) {
      throw new Error(
        `Entity ${JSON.stringify(
          entity
        )} matched on '${key}' does not have _id. Something has gone very wrong.`
      );
    }

    acc[key] = [...(acc[key] ?? []), id];

    return acc;
  };

  return entities.reduce(reducer, {});
};

/**
 * return an array of all duplicate matches.
 *
 * @param {Object} o
 * @param {object[]} o.entities
 * @param {string[]} o.dataIndexes - dataIndex to match on
 *
 * @returns {Record<string, string[]>}
 */
export const getDuplicateEntityMatches = ({ entities, dataIndexes }) => {
  /**
   * @param {Record<string, string[]>}  acc
   * @param {[key, string[]]} entry
   *
   * @returns {Record<string, string[]>}
   */
  const reducer = (acc, [key, matches]) => {
    if (matches.length < 2) {
      return acc;
    }

    acc[key] = matches;
    return acc;
  };

  const matches = reduceMatches({ entities, dataIndexes });

  return Object.entries(matches).reduce(reducer, {});
};

/**
 * return an array of all duplicate matches.
 *
 * @param {Object} o
 * @param {object[]} o.entities
 * @param {string[]} o.dataIndexes - dataIndex to match on
 *
 * @returns {Record<string, string>}
 */
export const getEntityMatches = ({ entities, dataIndexes }) => {
  /**
   * @param {Record<string, string>}  acc
   * @param {[key, string[]]} entry
   *
   * @returns {Record<string, string>}
   */
  const reducer = (acc, [key, matches]) => {
    if (matches.length !== 1) {
      return acc;
    }

    acc[key] = matches[0];
    return acc;
  };

  const matches = reduceMatches({ entities, dataIndexes });

  return Object.entries(matches).reduce(reducer, {});
};
