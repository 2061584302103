// @ts-check

/** @typedef {import("@evolved/domain").SystemField} SystemField */

import { reduceFieldsToMap } from "./reduce-fields-to-map.js";

/** @type {SystemField} */
export const performedOn = {
  entityType: "ACTIVITY",
  dataIndex: "performedOn",
  title: "Performed On",
  type: "ACTIVITY_DATE",
  isSystem: true,
};

/** @type {SystemField} */
export const summary = {
  entityType: "ACTIVITY",
  dataIndex: "summary",
  title: "Summary",
  type: "TEXT",
  isSystem: true,
};

/** @type {SystemField} */
export const account = {
  entityType: "ACTIVITY",
  collection: "accounts",
  dataIndex: "accountId",
  title: "Account",
  type: "SET",
  isSortable: true,
  relationship: {
    entityType: "ACCOUNT",
    cardinality: "one",
  },
  isSystem: true,
};

/** @type {SystemField} */
export const contacts = {
  entityType: "ACTIVITY",
  collection: "contacts",
  dataIndex: "contacts",
  title: "Contacts",
  type: "SET",
  relationship: {
    entityType: "CONTACT",
    cardinality: "many",
  },
  isSystem: true,
};

/** @type {SystemField} */
export const opportunity = {
  entityType: "ACTIVITY",
  collection: "opportunities",
  dataIndex: "opportunityId",
  title: "Opportunity",
  type: "SET",
  isSortable: true,
  relationship: {
    entityType: "OPPORTUNITY",
    cardinality: "one",
  },
  isSystem: true,
};

/** @type {SystemField} */
export const type = {
  entityType: "ACTIVITY",
  collection: "activityTypes",
  dataIndex: "typeId",
  title: "Type",
  type: "SET",
  isSortable: true,
  relationship: {
    entityType: "ACTIVITY_TYPE",
    cardinality: "one",
  },
  isSystem: true,
};

/** @type {SystemField} */
export const users = {
  entityType: "ACTIVITY",
  collection: "users",
  dataIndex: "users",
  title: "Sales Reps",
  type: "SET",
  relationship: {
    entityType: "USER",
    cardinality: "many",
  },
  isSystem: true,
};

/** @type {SystemField} */
export const vendors = {
  entityType: "ACTIVITY",
  collection: "vendors",
  dataIndex: "vendors",
  title: "Partners",
  type: "SET",
  relationship: {
    entityType: "VENDOR",
    cardinality: "many",
  },
  isSystem: true,
};

export const COLLECTION = Object.freeze([
  performedOn,
  summary,
  account,
  contacts,
  opportunity,
  type,
  users,
  vendors,
]);

export const DEFAULT_FIELDS = Object.freeze([
  "performedOn",
  "summary",
  "accountId",
  "contacts",
  "opportunityId",
  "typeId",
  "users",
  "vendors",
]);

/** @type {Readonly<string[]>} */
export const FIXED_FIELDS = [];

export const MAP = Object.freeze(reduceFieldsToMap(COLLECTION));
