import find from "lodash/find";
import get from "lodash/get";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isFinite from "lodash/isFinite";
import isNil from "lodash/isNil";

import { ClockCircleOutlined } from "@ant-design/icons";
import { Progress, Tag } from "antd";
import { Link } from "react-router-dom";

import { calculateRow, displayDay } from "@evolved/views";
import { colors, fieldTypes } from "@evolved/constants";

import {
  CREATE_ACCOUNT_FOLLOWUP_MODAL,
  CREATE_CONTACT_FOLLOWUP_MODAL,
  CREATE_OPPORTUNITY_FOLLOWUP_MODAL,
  CREATE_VENDOR_FOLLOWUP_MODAL,
  PERFORM_ACCOUNT_ACTIVITY_MODAL,
  PERFORM_CONTACT_ACTIVITY_MODAL,
  PERFORM_OPPORTUNITY_ACTIVITY_MODAL,
  PERFORM_VENDOR_ACTIVITY_MODAL,
  useLayoutStore,
} from "stores/layout";
import { AccountState } from "components/account-state";
import { ActivityDate } from "components/activity-date";
import { FollowupDate } from "components/followup-date";
import { ValidUrl } from "components/valid-url";
import { formatDollars } from "utils/format-dollars";
import { AccountsSet } from "./accounts-set";
import { ContactsSet } from "./contacts-set";
import { LossReasonsSet } from "./loss-reasons-set";
import { OpportunitiesSet } from "./opportunities-set";
import { OpportunityStatesSet } from "./opportunity-states-set";
import { ProductsSet } from "./products-set";
import { SalesProcessesSet } from "./sales-processes-set";
import { TagsSet } from "./tags-set";
import { UsersSet } from "./users-set";
import { VendorsSet } from "./vendors-set";

const { PRIMARY } = colors;

const {
  ACCOUNT_STATE,
  ACTIVITY_DATE,
  CALCULATED,
  DATE,
  DOLLAR,
  FOLLOWUP_DATE,
  LINK,
  NUMBER,
  PERCENT,
  SELECT,
  SET,
  TEXT,
} = fieldTypes;

const componentsMap = {
  [ACTIVITY_DATE]: (value, row) => {
    const modalKey = {
      ACCOUNT: PERFORM_ACCOUNT_ACTIVITY_MODAL,
      CONTACT: PERFORM_CONTACT_ACTIVITY_MODAL,
      OPPORTUNITY: PERFORM_OPPORTUNITY_ACTIVITY_MODAL,
      VENDOR: PERFORM_VENDOR_ACTIVITY_MODAL,
    }[row.type];

    if (!modalKey) {
      return value ? <ActivityDate date={value} /> : "-";
    }

    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          useLayoutStore
            .getState()
            .open(modalKey, { [`${row.type.toLowerCase()}Id`]: row._id });
        }}
      >
        {value ? <ActivityDate date={value} /> : "-"}
      </div>
    );
  },
  [ACCOUNT_STATE]: (value) => (
    <AccountState account={{ state: value }} style={{ marginLeft: "0px" }} />
  ),
  [CALCULATED]: (_value, row, config) => {
    const result = calculateRow(row, config);
    return result === null ? "--" : result;
  },
  [DATE]: (value) =>
    value ? (
      <Tag>
        <ClockCircleOutlined style={{ marginRight: "5px" }} />
        {displayDay(value)}
      </Tag>
    ) : (
      "-"
    ),
  [DOLLAR]: (value) => (!isNil(value) ? formatDollars(value) : "-"),
  [FOLLOWUP_DATE]: (value, row) => {
    const modalKey = {
      ACCOUNT: CREATE_ACCOUNT_FOLLOWUP_MODAL,
      CONTACT: CREATE_CONTACT_FOLLOWUP_MODAL,
      OPPORTUNITY: CREATE_OPPORTUNITY_FOLLOWUP_MODAL,
      VENDOR: CREATE_VENDOR_FOLLOWUP_MODAL,
    }[row.type];

    if (!modalKey) {
      return value ? <FollowupDate date={value} /> : "-";
    }

    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          useLayoutStore
            .getState()
            .open(modalKey, { [`${row.type.toLowerCase()}Id`]: row._id });
        }}
      >
        {value ? <FollowupDate date={value} /> : "-"}
      </div>
    );
  },
  [LINK]: (value) => (value ? <ValidUrl url={value} /> : "-"),
  [NUMBER]: (value) => (isFinite(Number(value)) ? value : "-"),
  [PERCENT]: (value) =>
    !isNil(value) ? (
      <Progress
        percent={value}
        {...(value < 100 ? { status: "active" } : {})}
        strokeColor={PRIMARY}
      />
    ) : (
      "-"
    ),
  [SELECT]: (value, row, config) => {
    const { options = [] } = config;

    return (
      find(options, (o) => o.id === value && !o.isArchived) || { label: "-" }
    ).label;
  },
  [SET]: (value, row, config) => {
    if (!isArray(value)) {
      value = [value];
    }

    if (isEmpty(value)) {
      return "-";
    }

    const Component = {
      accounts: AccountsSet,
      contacts: ContactsSet,
      lossReasons: LossReasonsSet,
      opportunities: OpportunitiesSet,
      opportunityStates: OpportunityStatesSet,
      products: ProductsSet,
      salesProcesses: SalesProcessesSet,
      tags: TagsSet,
      users: UsersSet,
      vendors: VendorsSet,
    }[config.collection];

    if (!Component) {
      return "-";
    }

    return <Component value={value} />;
  },
  [TEXT]: (value, row, config) => {
    if (!value) {
      return "-";
    }

    if (config.mapUiUrl) {
      return <Link to={config.mapUiUrl(row._id)}>{value}</Link>;
    }

    return value;
  },
};

export const renderView = ({ column, row }) => {
  return componentsMap[column.type](get(row, column.dataIndex), row, column);
};
