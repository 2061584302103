// @ts-check

/** @typedef {import("@evolved/domain").SystemField} SystemField */

import { reduceFieldsToMap } from "./reduce-fields-to-map.js";

/** @type {SystemField} */
export const address = {
  entityType: "VENDOR",
  dataIndex: "address",
  title: "Address",
  type: "TEXT",
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const contacts = {
  entityType: "VENDOR",
  collection: "contacts",
  dataIndex: "contacts",
  title: "Contacts",
  type: "SET",
  relationship: {
    entityType: "CONTACT",
    cardinality: "many",
  },
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const createdOn = {
  entityType: "VENDOR",
  dataIndex: "createdOn",
  title: "Added On",
  type: "DATE",
  isSystem: true,
  canEdit: false,
};

/** @type {SystemField} */
export const lastActedOn = {
  entityType: "VENDOR",
  dataIndex: "lastActedOn",
  title: "Last Acted On",
  type: "ACTIVITY_DATE",
  isSystem: true,
  canEdit: false,
};

/** @type {SystemField} */
export const name = {
  entityType: "VENDOR",
  dataIndex: "name",
  title: "Name",
  type: "TEXT",
  mapUiUrl: (value) => `/partners/${value}`,
  isSystem: true,
  isRequired: true,
  canMatchEntityBy: "is_default",
  canEdit: true,
};

/** @type {SystemField} */
export const nextFollowup = {
  entityType: "VENDOR",
  dataIndex: "nextFollowupOn",
  title: "Next Followup",
  type: "FOLLOWUP_DATE",
  isSystem: true,
  canEdit: false,
};

/** @type {SystemField} */
export const phone = {
  entityType: "VENDOR",
  dataIndex: "phone",
  title: "Phone",
  type: "TEXT",
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const users = {
  entityType: "VENDOR",
  collection: "users",
  dataIndex: "users",
  title: "Sales Reps",
  type: "SET",
  relationship: {
    entityType: "USER",
    cardinality: "many",
  },
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const tags = {
  entityType: "VENDOR",
  collection: "tags",
  dataIndex: "tags",
  title: "Tags",
  type: "SET",
  relationship: {
    entityType: "TAG",
    cardinality: "many",
  },
  isSystem: true,
  canEdit: true,
};

// NOTE: determines the order in which
// things come up in the system.
// In the views, exports, etc.
export const COLLECTION = Object.freeze([
  name,
  createdOn,
  nextFollowup,
  lastActedOn,
  address,
  phone,
  contacts,
  tags,
  users,
]);

/** @type {Readonly<string[]>} */
export const DEFAULT_FIELDS = [];
export const FIXED_FIELDS = Object.freeze(["name"]);

export const MAP = Object.freeze(reduceFieldsToMap(COLLECTION));
