import moment from "moment";

/**
 * @param {string | number} date
 */
export const displayDay = (date) => {
  if (typeof date === "number") {
    return moment.unix(date).format("MMM DD Y");
  }

  return moment(date).format("MMM DD Y");
};
