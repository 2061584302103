import { UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
// import { useEffect } from "react";

import { useModal } from "../../../hooks/use-modal";
import { ImportDataModal } from "./import-data";
import { ImportDataModal as ImportDataModalDeprecated } from "../import-data-deprecated";
import { useFlag } from "../../../hooks/use-flag";

export const OpenImportModal = (props) => {
  const { type } = props;

  // const { modal, open, isOpen } = useModal(
  const { modal, open } = useModal(
    useFlag("import-wizard") ? ImportDataModal : ImportDataModalDeprecated
  );

  // useEffect(() => {
  //   if (!isOpen) {
  //     open({ type })
  //   }
  // })

  return (
    <>
      {modal}
      <Button
        ghost
        icon={<UploadOutlined />}
        id={`import-${type}-button`}
        onClick={() => open({ type })}
        type="primary"
      >
        Import
      </Button>
    </>
  );
};
