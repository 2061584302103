// @ts-check

/**
 * @typedef {import("./domain").ImportField} ImportField
 */

/**
 * @param {string} dataIndex
 * @returns {(importFields: ImportField[]) => ImportField}
 */
export const findField = (dataIndex) => {
  return (importFields) => {
    const found = importFields.find((field) => {
      return field.dataIndex === dataIndex;
    });

    if (!found) {
      throw new Error(`No importField found for dataIndex: ${dataIndex}.`);
    }

    return found;
  };
};
