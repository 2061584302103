// @ts-check

import { findField } from "./find-field";

/** @typedef {import("./domain").HeaderValues<string>} Result

/**
 * @param {Object} o
 * @param {string | null} o.dataIndex
 * @param {import("./domain").ImportField[]} o.importFields
 * @returns {boolean}
 */
export const isRelationshipField = ({ dataIndex, importFields }) => {
  if (!dataIndex) {
    return false;
  }

  const field = findField(dataIndex)(importFields);

  return field.type === "SET";
};

/**
 * @param {Object} o
 * @param {(string | null)[]} o.headerDataIndexes
 * @param {import("./domain").ImportField[]} o.importFields
 * @param {(import("./domain").EntityMatchConfig | null)[]} [o.relationshipMatchConfigs]
 * @returns {Result}
 */
export const getUnconfiguredRelationships = ({
  headerDataIndexes,
  importFields,
  relationshipMatchConfigs = [],
}) => {
  /**
   * @param {Result} acc
   * @param {string | null} dataIndex
   * @param {number} index
   * @returns {Result}
   */
  const reducer = (acc, dataIndex, index) => {
    if (dataIndex) {
      const field = findField(dataIndex)(importFields);

      if (field.type === "SET" && !relationshipMatchConfigs[index]) {
        acc.push(dataIndex);
        return acc;
      }
    }

    acc.push(null);
    return acc;
  };

  return headerDataIndexes.reduce(reducer, []);
};
