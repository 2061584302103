// @ts-check

import { normalize } from "./are-headers-equal";

/**
 * Create options reducer to track and
 * return ids based on normalized values.
 *
 * If called with an empty string, null
 * will be returned. It is up to the caller
 * to handler that case.
 *
 * @param {Object} o
 * @param {() => string} o.generateId
 *
 * @returns {{
 *   getId: (value: string) => string | null;
 *   getOptions: () => {label: string; id: string;}[]
 * }}
 */
export const createOptionsReducer = ({ generateId }) => {
  /** @type{Map<string, {label: string; id: string;}>} */
  const options = new Map();

  /**
   * @param {string} value
   *
   * @returns {string | null}
   */
  const getId = (value) => {
    if (!value) {
      return null;
    }

    let id = options.get(normalize(value))?.id;

    if (id) {
      return id;
    }

    id = generateId();
    options.set(normalize(value), { label: value, id });

    return id;
  };

  const getOptions = () => {
    return new Array(...options.values());
  };

  return {
    getId,
    getOptions,
  };
};
