import isArray from "lodash/isArray.js";
import lowerCase from "lodash/lowerCase.js";
import moment from "moment";

import { dateFilters } from "@evolved/constants";

const { OPTIONS } = dateFilters;

export const filter = (value, filter) => {
  const m = typeof value === "number" ? moment.unix(value) : moment(value);

  if (isArray(filter)) {
    return m.isBetween(moment.unix(filter[0]), moment.unix(filter[1]));
  }

  const [tense, quantity, unit] = filter.split("_");

  if (tense === "FUTUREYM") {
    // quantity = YEAR
    // unit = MONTH
    return m.isBetween(
      moment().year(quantity).month(unit).startOf("month"),
      moment().year(quantity).month(unit).endOf("month")
    );
  }

  if (tense === "FUTURE") {
    return m.isBetween(
      moment(),
      moment().add(Number(quantity), lowerCase(unit))
    );
  }

  return m.isBetween(
    moment().subtract(Number(quantity), lowerCase(unit)),
    moment()
  );
};

export const validate = (filter) => Object.keys(OPTIONS).includes(filter);
