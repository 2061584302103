// @ts-check

import { smartTrim } from "./smart-trim.js";

/**
 * Smart trim and lowercase strings
 * for testing.
 *
 * @param {string} s
 *
 * @returns {string}
 */

export const normalizeHeader = (s) => {
  if (s === null || s === undefined) {
    return "";
  }

  return smartTrim(s).toLowerCase();
};
