// @ts-check

import { getEntityMatchKey } from "./get-entity-matches";
import { zipRow } from "./zip-row";

/**
 * @param {Object} o
 * @param {string[]} o.row
 * @param {(string | null)[]} o.headerDataIndexes
 * @param {string[]} o.dataIndexes
 *
 */
export const getRowMatchKey = ({ row, headerDataIndexes, dataIndexes }) => {
  const zipped = zipRow({
    headerDataIndexes,
    row,
  });

  return getEntityMatchKey({ dataIndexes, entity: zipped });
};
