// @ts-check

/**
 * Remove:
 *
 * - all whitespace at the start and end
 * - remove multiple spaces in between words
 *
 * @param {string} s
 *
 * @returns {string}
 */
export const smartTrim = (s) => {
  return s.replace(/\s+/g, " ").trim();
};
