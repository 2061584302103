import day from "dayjs";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

import {
  DatePicker as DatePickerBase,
  Form,
  Input,
  InputNumber,
  Select,
} from "antd";

import { fieldTypes } from "@evolved/constants";

import { useOrganization } from "../../data/use-organization";
import { filterOption } from "../selects/filter-option";
import { pickFormItemProps } from "../selects/pick-props";

const { DATE, NUMBER, SELECT, TEXT } = fieldTypes;

const Label = (props) => {
  return (
    <div style={{ whiteSpace: "normal", lineHeight: "16px" }}>
      {props.children}
    </div>
  );
};

const DatePicker = ({ value, onChange }) => {
  return (
    <DatePickerBase
      format={"YYYY-MM-DD"}
      onChange={(date) => {
        return onChange(date && date.format("YYYY-MM-DD"));
      }}
      value={
        value && (typeof value === "number" ? day.unix(value) : day(value))
      }
    />
  );
};

const FIELD_TYPE_MAP = {
  [NUMBER]: (props) => {
    return (
      <Form.Item
        {...pickFormItemProps(props)}
        label={<Label>{props.label}</Label>}
      >
        <InputNumber />
      </Form.Item>
    );
  },
  [DATE]: (props) => {
    return (
      <Form.Item
        {...pickFormItemProps(props)}
        label={<Label>{props.label}</Label>}
      >
        <DatePicker />
      </Form.Item>
    );
  },
  [SELECT]: (props) => {
    const { field } = props;
    const { options = [] } = field;

    return (
      <Form.Item
        {...pickFormItemProps(props)}
        label={<Label>{props.label}</Label>}
      >
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
          options={options
            .filter((o) => !o.isArchived)
            .map((o) => ({
              value: o.id,
              label: o.label,
            }))}
        />
      </Form.Item>
    );
  },
  [TEXT]: (props) => {
    return (
      <Form.Item
        {...pickFormItemProps(props)}
        label={<Label>{props.label}</Label>}
      >
        <Input />
      </Form.Item>
    );
  },
};

const renderField = (field) => {
  const { id, dataType, description, name } = field;

  const Field = FIELD_TYPE_MAP[dataType];

  return (
    Field && (
      <Field
        field={field}
        key={id}
        label={name}
        labelHint={description}
        name={["userDefinedFields", id]}
      />
    )
  );
};

export const UserDefinedFieldInputs = ({ type }) => {
  const organization = useOrganization();

  const fields = filter(organization.userDefinedFields, { type });

  if (isEmpty(fields)) {
    return null;
  }

  return map(fields, renderField);
};
