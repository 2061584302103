import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, InputNumber, message, Select } from "antd";
import { forwardRef, useEffect, useRef, useState } from "react";
import day from "dayjs";

import get from "lodash/get";
import isNull from "lodash/isNull";
import noop from "lodash/noop";
import sortBy from "lodash/sortBy";

import { fieldTypes } from "@evolved/constants";

import { useFieldConfigs } from "../../../use-field-configs";

const { DATE, NUMBER, SELECT, TEXT } = fieldTypes;

const FIELD_TYPE_MAP = {
  [NUMBER]: forwardRef((props, ref) => {
    const { pendingValue, save, setIsEditing, value, ...rest } = props;

    const isLoading = !isNull(pendingValue);

    return (
      <InputNumber
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            save(ref.current.value);
          }
        }}
        defaultValue={value}
        ref={ref}
        disabled={isLoading}
        addonAfter={isLoading ? <LoadingOutlined /> : null}
        {...rest}
      />
    );
  }),
  [DATE]: forwardRef((props) => {
    return (
      <DatePicker
        format={"YYYY-MM-DD"}
        open={true}
        onOpenChange={props.setIsEditing}
        onChange={(date) => {
          return props.save(date.format("YYYY-MM-DD"));
        }}
        value={
          props.value &&
          (typeof props.value === "number"
            ? day.unix(props.value)
            : day(props.value))
        }
      />
    );
  }),
  [SELECT]: forwardRef((props, ref) => {
    const { fieldConfig, pendingValue, save, setIsEditing, value } = props;
    const { options = [] } = fieldConfig;

    const option = options.find((o) => o.id === value && !o.isArchived);

    return (
      <Select
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        listHeight={128}
        loading={pendingValue}
        onBlur={() => setIsEditing(false)}
        onChange={save}
        open={!pendingValue}
        optionFilterProp="children"
        options={sortBy(
          options
            .filter((o) => !o.isArchived)
            .map(({ id, label }) => ({ label, value: id })),
          ({ label }) => label && label.toUpperCase()
        )}
        ref={ref}
        showSearch={!pendingValue}
        style={{ width: "100%" }}
        value={pendingValue || option?.id}
      />
    );
  }),
  // TODO: test the TEXT input type
  [TEXT]: forwardRef((props, ref) => {
    const { pendingValue, save, setIsEditing, value, ...rest } = props;

    const isLoading = !isNull(pendingValue);

    return (
      <>
        <div style={{ display: "flex", marginBottom: "8px" }}>
          <Button
            icon={<SaveOutlined />}
            loading={isLoading}
            // TODO: in particular test this
            onClick={() => save(ref.current.resizableTextArea.textArea.value)}
            size="small"
            style={{ flexBasis: 0, marginRight: 8 }}
            type="primary"
          >
            Save
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => setIsEditing(false)}
            size="small"
            style={{ flexBasis: 0 }}
          >
            Cancel
          </Button>
        </div>
        <Input.TextArea
          autoSize
          defaultValue={value}
          disabled={isLoading}
          ref={ref}
          {...rest}
        />
      </>
    );
  }),
};

const RevertEdit = (props) => {
  useEffect(() => {
    props.setIsEditing(false);
  });

  return null;
};

export const EditableUserDefinedFieldCell = (props) => {
  const {
    isEditing,
    setIsEditing,
    children,
    dataIndex,
    onUpdate,
    validate = noop,
    value,
    viewType,
  } = props;

  const fieldConfigs = useFieldConfigs(viewType);

  const [pendingValue, setPendingValue] = useState(null);
  const inputRef = useRef();

  useEffect(() => {
    if (isEditing && inputRef.current) {
      if (inputRef?.current?.resizableTextArea?.textArea?.value) {
        const end = inputRef.current.resizableTextArea.textArea.value.length;

        inputRef.current.resizableTextArea.textArea.setSelectionRange(end, end);
      }

      inputRef.current.focus();
    }
  }, [isEditing]);

  const fieldConfig = get(fieldConfigs, dataIndex) || {};

  const { type } = fieldConfig;

  const TypeInput = FIELD_TYPE_MAP[type];

  if (!TypeInput) {
    return (
      <>
        {children}
        <RevertEdit setIsEditing={setIsEditing} />
      </>
    );
  }

  const save = async (value) => {
    const error = validate(value);

    if (!error) {
      setPendingValue(value);
      const id = dataIndex.split(".")[1];

      const userDefinedFields = {};

      if (id) {
        userDefinedFields[id] = value;
      }

      const isSuccess = await onUpdate(userDefinedFields);

      if (isSuccess) {
        setIsEditing(false);
      }

      setPendingValue(null);
    } else {
      message.warning(error);
    }
  };

  return isEditing ? (
    <TypeInput
      defaultValue={props.value}
      fieldConfig={fieldConfig}
      ref={inputRef}
      save={save}
      setIsEditing={setIsEditing}
      pendingValue={pendingValue}
      value={value}
    />
  ) : (
    children
  );
};
