import "@evolved/domain";

import { StyleProvider } from "@ant-design/cssinjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "antd/dist/reset.css";
import ReactDOM from "react-dom/client";
import { App as AntdProvider } from "antd";

import App from "./app";
import { NotificationProvider } from "./contexts/notification/use-notification";
import { ThemeProvider } from "./contexts/theme/use-theme";
import "./index.css";
import "./setup-day";
import "./setup-pdf";

import { ClerkProvider } from "@clerk/clerk-react";

// import logo from "./assets/navbar-logo-dark.png";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      retry: 3,
      staleTime: 15 * 60 * 1000,
      useErrorBoundary: true,
    },
  },
});

{
  /*
  StyleProvider.hashPriority: Make antd styles a higher priority than tailwind preflight.
  Specifically for button styles.
  Details:
    https://ant.design/docs/react/customize-theme#compatible-adjustment and
    https://github.com/tailwindlabs/tailwindcss/discussions/5969
  */
}

const container = document.getElementById("root");

if (!container) {
  throw new Error("element with id 'root' not found.");
}

// todo: consider bringing back Strict mode. Left out because it's annoying.
ReactDOM.createRoot(container).render(
  // <div className="w-screen h-screen flex justify-center mt-72">
  //   <div className="animate-pulse">
  //     <img
  //       alt="logo"
  //       style={{ height: "48px", marginBottom: "8px" }}
  //       src={logo}
  //     />
  //     <div>Improving our systems, be back online by 12PM MT 🚀</div>
  //   </div>
  // </div>
  <StyleProvider hashPriority="high">
    <ClerkProvider publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <ThemeProvider>
          <AntdProvider>
            <NotificationProvider>
              <div className="h-screen w-screen bg-slate-50 dark:bg-slate-900">
                <App />
              </div>
            </NotificationProvider>
          </AntdProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </ClerkProvider>
  </StyleProvider>
);
