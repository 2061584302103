// @ts-check

import React, { useState } from "react";
import { Button, Checkbox, Select } from "antd";

import { getDuplicateEntityMatches, getEntityMatchKey, getEntityMatches } from "./get-entity-matches";
import { getRowMatchKey } from "./get-row-match-key";

// TODO: can maybe used for relationships as well?

/** 
 * @typedef {Object} Props
 *
 * @prop {(string | null)[]} headerDataIndexes
 * @prop {import("./domain").CSVUpload} upload
 * @prop {import("./use-entity-cache").EntityCache} entityCache
 * @prop {import("./domain").ImportableEntityTypes} entityType
 * @prop {import("./domain").ImportField[]} importFields
 *
 * @prop {() => void} onBack
 * @prop {(entityMatchConfig: import("./domain").EntityMatchConfig) => void} onNext
 */

/**
 * @param {Props} props 
 */
export const ConfigureEntityMatch = ({
  headerDataIndexes,
  upload,
  entityCache,
  entityType,
  importFields,
  onNext,
  onBack
}) => {

  // TODO: confirm this is all done
  // add importFields
  // filter import fields to be those that
  // have canMatchEntityBy,
  // if the canMatchEntityBy (CRM ID) priority is a headerIndex
  // then use that
  //
  // if not, then use defaults (if available)
  //
  // if nothing is available, then we tell the user that
  // all entities will be created as there is nothing to match
  //
  // for relationships... the difference is
  // the match fields that are passed in... maybe they are
  // passed in? will see.

  // so... the whole job of this component is to select
  // one or many options

  // NOTE: Should only ever be one. CRM ID. But,
  // maybe we can do more in the future.
  const priorityMatchField = importFields.find((field) => {
    if (field.type !== "LINK" && field.type !== "TEXT") {
      return false;
    }

    return field.canMatchEntityBy === "is_priority" && headerDataIndexes.includes((field.dataIndex));
  });

  const defaultDataIndexes = priorityMatchField ? [priorityMatchField.dataIndex] : importFields.filter((field) => {
    if (field.type !== "LINK" && field.type !== "TEXT") {
      return false;
    }

    return field.canMatchEntityBy === "is_default" && headerDataIndexes.includes(field.dataIndex);
  }).map(({ dataIndex }) => dataIndex);

  const requiredFields = importFields.filter(({ isRequired }) => {
    return !!isRequired;
  });

  const allRequiredFieldsSelected = requiredFields.every((requiredField) => {
    return headerDataIndexes.includes(requiredField.dataIndex);
  });

  // TODO: Figure out this UX later, for now, simply default to true based
  // on the required fields.
  const [createIfNoMatch, setCreateIfNoMatch] = useState(allRequiredFieldsSelected);
  const [dataIndexes, setDataIndexes] = useState(defaultDataIndexes);
  const [isMulti, setIsMulti] = useState(defaultDataIndexes.length > 1);

  const options = importFields.filter((field) => {
    if (field.type !== "LINK" && field.type !== "TEXT") {
      return false;
    }

    return !!field.canMatchEntityBy && headerDataIndexes.includes(field.dataIndex);
  }).map(({ dataIndex, name }) => ({ label: name, value: dataIndex }));

  const duplicates = getDuplicateEntityMatches({ entities: entityCache[entityType], dataIndexes });
  const matches = getEntityMatches({ entities: entityCache[entityType], dataIndexes });

  const keyRows = {};

  /**
   * @param {{
   *  duplicates: number[];
   *  matched: number[];
   *  unmatched: number[];
   * }} acc
   * @param {string[]} row
   * @param {number} index
   */
  const reduceResult = (acc, row, index) => {
    const key = getRowMatchKey({ dataIndexes, headerDataIndexes, row })
    if (!key) {
      return acc;
    }

    keyRows[key] = [...keyRows[key] ?? [], index];

    if (duplicates[key]) {
      acc.duplicates.push(index);
    } else if (matches[key]) {
      acc.matched.push(index);
    } else {
      acc.unmatched.push(index);
    }

    return acc;
  }

  const result = upload.rows.reduce(reduceResult, {
    duplicates: [],
    matched: [],
    unmatched: [],
  });

  console.log({ duplicates, matches, keyRows, result })

  // TODO:
  //
  // - show entityDuplicates
  // - show duplicates IF any rawEntities match
  //

  // so now... based on your selections, there are 
  // some duplicates that we'll ignore (only show if anything
  // matches it)

  // TODO: better UX to explain what fields are
  // required to create.
  // TODO: do we want to provide UX to show if there
  // are duplicates in the import or in CRM? yes probably.
  return (
    <div className="w-full">
      {priorityMatchField ? (
        <div>Find matches by <strong>{priorityMatchField.name}</strong>. If more than one match is found, nothing will be updated. There must be exactly one match in the import to an entity in CRM.</div>
      ) : (
        <>
          <div className="mb-2">
            What field(s) would you like to use to match rows in your data to records in CRM?
          </div>
          <div className="flex items-start mb-2">
            <Checkbox
              checked={isMulti}
              onChange={() => {
                if (isMulti && dataIndexes.length > 1) {
                  setDataIndexes([dataIndexes[0]]);
                }

                setIsMulti(!isMulti);
              }}
              style={{ marginRight: "8px" }}
            />
            <div>Match on multiple fields?</div>
          </div>
          <div className="flex mb-2">
            <Select
              className="w-full"
              mode={isMulti ? "multiple" : undefined}
              value={dataIndexes}
              options={options}
              onChange={setDataIndexes}
            />
          </div>
        </>
      )}

      {allRequiredFieldsSelected ? (
        <div className="flex items-start">
          <Checkbox
            onChange={(e) => {
              setCreateIfNoMatch(e.target.checked);
            }}
            checked={createIfNoMatch}
            style={{ marginRight: "8px" }}
          />
          <div>If no matches are found for a row, would you like CRM to create a new entity?</div>
        </div>
      ) : (
        <div>If no matches are found, CRM will ignore the row as required columns <strong>{requiredFields.map(({ name }) => name).join()}</strong> are missing.</div>
      )}
      <div className="flex justify-end">
        <Button className="mr-2" onClick={onBack}>
          Back
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onNext({
              ...(dataIndexes.length ? { dataIndexes } : {}),
              createIfNoMatch
            });
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
