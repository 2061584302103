// @ts-check

import { createOptionsReducer } from "./create-options-reducer";

/**
 * @param {Object} o
 * @param {string[][]} o.rows
 * @param {number} o.index
 * @param {() => string} o.generateId
 */
export const reduceColumnOptions = ({ rows, index, generateId }) => {
  const optionsReducer = createOptionsReducer({ generateId });

  for (const row of rows) {
    optionsReducer.getId(row[index]);
  }

  return optionsReducer.getOptions();
};
