// @ts-check

import { getDuplicateHeaders } from "./get-duplicate-headers";
import { getDefaultHeaderDataIndexes } from "./get-default-header-data-indexes";
import {
  getUnconfiguredRelationships,
  isRelationshipField,
} from "./get-unconfigured-relationships";
import { CREATE_USER_DEFINED_FIELD_OPTION } from "./identify-headers";

/**
 * Reduces the current state to determine valid
 * states for the user to navigate to.
 *
 * If user state has not specified a stepId,
 * then push the user to the last state.
 *
 * @param {Object} o
 * @param {import("./domain").ImportUserState} o.state
 * @param {import("./domain").ImportUserState} o.previousState
 * @param {import("./domain").ImportField[]} o.importFields
 *
 * @returns {import("./domain").ImportReducedState[]}
 *
 */
export const reduceState = ({ state, previousState, importFields }) => {
  /** @type {import("./domain").UploadFileState} */
  const uploadFileStep = { stepId: "upload_file" };

  /** @type {import("./domain").ImportReducedState[]} */
  const steps = [uploadFileStep];

  if (!state.upload) {
    return steps;
  }

  const duplicateHeaders = getDuplicateHeaders(state.upload.headers);

  if (duplicateHeaders.length) {
    uploadFileStep.errors = [
      {
        type: "duplicate_headers",
        duplicateHeaders,
      },
    ];

    return steps;
  }

  steps.push({
    stepId: "configure_header_data_indexes",
    headers: state.upload.headers,
    defaults: getDefaultHeaderDataIndexes({
      importFields,
      headers: state.upload.headers,
    }),
  });

  if (!state.headerDataIndexes) {
    return steps;
  }

  if (
    state.headerDataIndexes.some(
      (dataIndex) => dataIndex === CREATE_USER_DEFINED_FIELD_OPTION.value
    )
  ) {
    steps.push({
      stepId: "create_user_defined_fields",
      headerDataIndexes: state.headerDataIndexes,
      upload: state.upload,
    });
  }

  if (
    state.headerDataIndexes.some((dataIndex, index) => {
      return (
        dataIndex === CREATE_USER_DEFINED_FIELD_OPTION.value &&
        !state.newUserDefinedFields?.[index]
      );
    })
  ) {
    return steps;
  }

  steps.push({
    stepId: "configure_entity_match",
    upload: state.upload,
    headerDataIndexes: state.headerDataIndexes,
  });

  if (!state.entityMatchConfig) {
    return steps;
  }

  if (
    state.headerDataIndexes.some((dataIndex) => {
      return isRelationshipField({ dataIndex, importFields });
    })
  ) {
    steps.push({
      stepId: "configure_relationships_matches",
      headerDataIndexes: state.headerDataIndexes,
      upload: state.upload,
    });

    const unconfiguredRelationships = getUnconfiguredRelationships({
      relationshipMatchConfigs: state.relationshipMatchConfigs,
      importFields,
      headerDataIndexes: state.headerDataIndexes,
    });

    if (unconfiguredRelationships.filter((dataIndex) => !!dataIndex).length) {
      return steps;
    }
  }

  // TODO:
  //
  // - get rid of previousState, there is just state,
  // and we just delete stuff WHEN and only when it
  // makes sense... have to determine when we do that
  // on upload (how do we know we can reuse state if
  // the users upload is different? give them the
  // option? only reuse what matches based on index?
  //
  // - check to see if entity has a match...
  // and now... I think we have all the info we need
  // to iterate through the data and create stuff.
  // Now, it gets fun.

  // okay... so now that you've made header selections,
  // which are relationships? and what do they need?

  // what are the available

  // now I want to return that the user needs to get
  // the header config
  // and the heaer config at the moment is... what

  // so, now in order to do the missing name rows, and
  // reconcile rows and all of that, we must

  // once we have an upload,
  // then we want to figure if there are duplicate headers

  // figure out duplicate headers
  // figure out missing name rows
  // figure out rowMatches
  // must reconcileMultipleRowMatches
  //
  // TODO: any new options to add to select field, confirm,
  // and update.

  // let the user know that options will be filled in later
  // by the import data. can make better in the future.
  //
  // I care way more than the useres probably care for.

  steps.push({
    stepId: "confirm",
  });
  return steps;
};
