import noop from "lodash/noop";

import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

import { isFieldSortable } from "@evolved/domain";

import { useViewStore } from "../../../stores/view";
import { DraggableBlock } from "./draggable-block";
import { styles } from "./styles";

export const HeaderCell = (props) => {
  const { column, onWidthChange, viewType } = props;

  return (
    <th
      onClick={
        isFieldSortable(column)
          ? () => {
            if (column.sortOrder) {
              useViewStore.getState().toggleSortOrder(viewType)(
                column.dataIndex
              );
            } else {
              useViewStore.getState().setSortOrder(viewType)([
                {
                  dataIndex: column.dataIndex,
                  order: "ascend",
                },
              ]);
            }
          }
          : noop
      }
      style={{
        ...styles.cell,
        ...(isFieldSortable(column) ? { cursor: "pointer" } : {}),
        backgroundColor: "#fafafa",
        maxWidth: column.width,
        minWidth: column.width,
        padding: "4px",
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            marginLeft: "8px",
            marginRight: "auto",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          {column.title}
        </div>
        {isFieldSortable(column) && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CaretUpOutlined
              style={{
                color: column.sortOrder === "ascend" ? "#864999" : "#bcbcbc",
                cursor: "pointer",
                fontSize: "14px",
                marginBottom: "-4px",
              }}
            />
            <CaretDownOutlined
              style={{
                color: column.sortOrder === "descend" ? "#864999" : "#bcbcbc",
                cursor: "pointer",
                fontSize: "14px",
              }}
            />
          </div>
        )}
        <DraggableBlock onWidthChange={onWidthChange} width={column.width} />
      </div>
    </th>
  );
};
