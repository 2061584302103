/** @deprecated */
export const ACCOUNT = "ACCOUNT";
/** @deprecated */
export const ACTIVITY = "ACTIVITY";
/** @deprecated */
export const CONTACT = "CONTACT";
/** @deprecated */
export const OPPORTUNITY = "OPPORTUNITY";
/** @deprecated */
export const VENDOR = "VENDOR";
