/**
 * Type-safe includes check
 * @template {I} T - Type of items in collection
 * @template I - Type of element to check
 * @param {readonly T[]} collection
 * @param {I} item
 * @returns {item is T}
 */
export const includes = (collection, item) => {
  return collection.includes(/** @type {T} */ (item));
};
