import { ROLES, SUPER } from "./user-roles.js";

const { VIEWER, SALES, MANAGER, OWNER } = ROLES;

const RESPONSIBLE = "responsible";
const ASSIGNED = "assigned";

export const accessLevels = { RESPONSIBLE, ASSIGNED };

const buildEntityDefaults = (entity) => ({
  [`${entity}:create:${VIEWER}`]: false,
  [`${entity}:create:${SALES}`]: true,
  [`${entity}:create:${MANAGER}`]: true,
  [`${entity}:create:${OWNER}`]: true,
  [`${entity}:create:${SUPER}`]: true,

  [`${entity}:import:${VIEWER}`]: false,
  [`${entity}:import:${SALES}`]: true,
  [`${entity}:import:${MANAGER}`]: true,
  [`${entity}:import:${OWNER}`]: true,
  [`${entity}:import:${SUPER}`]: true,

  [`${entity}:view:${VIEWER}`]: true,
  [`${entity}:view:${SALES}`]: true,
  [`${entity}:view:${MANAGER}`]: true,
  [`${entity}:view:${OWNER}`]: true,
  [`${entity}:view:${SUPER}`]: true,

  [`${entity}:export:${VIEWER}`]: false,
  [`${entity}:export:${SALES}`]: true,
  [`${entity}:export:${MANAGER}`]: true,
  [`${entity}:export:${OWNER}`]: true,
  [`${entity}:export:${SUPER}`]: true,

  [`${entity}:assign:${VIEWER}`]: false,
  [`${entity}:assign:${SALES}`]: RESPONSIBLE,
  [`${entity}:assign:${MANAGER}`]: true,
  [`${entity}:assign:${OWNER}`]: true,
  [`${entity}:assign:${SUPER}`]: true,

  [`${entity}:edit:${VIEWER}`]: false,
  [`${entity}:edit:${SALES}`]: RESPONSIBLE,
  [`${entity}:edit:${MANAGER}`]: true,
  [`${entity}:edit:${OWNER}`]: true,
  [`${entity}:edit:${SUPER}`]: true,

  [`${entity}:archive:${VIEWER}`]: false,
  [`${entity}:archive:${SALES}`]: RESPONSIBLE,
  [`${entity}:archive:${MANAGER}`]: true,
  [`${entity}:archive:${OWNER}`]: true,
  [`${entity}:archive:${SUPER}`]: true,

  [`${entity}:delete:${VIEWER}`]: false,
  [`${entity}:delete:${SALES}`]: false,
  [`${entity}:delete:${MANAGER}`]: true,
  [`${entity}:delete:${OWNER}`]: true,
  [`${entity}:delete:${SUPER}`]: true,

  [`${entity}:log-activity:${VIEWER}`]: false,
  [`${entity}:log-activity:${SALES}`]: ASSIGNED,
  [`${entity}:log-activity:${MANAGER}`]: true,
  [`${entity}:log-activity:${OWNER}`]: true,
  [`${entity}:log-activity:${SUPER}`]: true,

  [`${entity}:self-assign-followup:${VIEWER}`]: false,
  [`${entity}:self-assign-followup:${SALES}`]: ASSIGNED,
  [`${entity}:self-assign-followup:${MANAGER}`]: true,
  [`${entity}:self-assign-followup:${OWNER}`]: true,
  [`${entity}:self-assign-followup:${SUPER}`]: true,
});

export const defaults = {
  ...buildEntityDefaults("contact"),
  ...buildEntityDefaults("account"),
  ...buildEntityDefaults("opportunity"),
  ...buildEntityDefaults("vendor"),

  [`opportunity:override-sales-process:${VIEWER}`]: false,
  [`opportunity:override-sales-process:${SALES}`]: false,
  [`opportunity:override-sales-process:${MANAGER}`]: true,
  [`opportunity:override-sales-process:${OWNER}`]: true,
  [`opportunity:override-sales-process:${SUPER}`]: true,

  [`followup:edit:${VIEWER}`]: false,
  [`followup:edit:${SALES}`]: ASSIGNED,
  [`followup:edit:${MANAGER}`]: true,
  [`followup:edit:${OWNER}`]: true,
  [`followup:edit:${SUPER}`]: true,

  [`followup:archive:${VIEWER}`]: false,
  [`followup:archive:${SALES}`]: ASSIGNED,
  [`followup:archive:${MANAGER}`]: true,
  [`followup:archive:${OWNER}`]: true,
  [`followup:archive:${SUPER}`]: true,

  [`activity:edit:${VIEWER}`]: false,
  [`activity:edit:${SALES}`]: RESPONSIBLE,
  [`activity:edit:${MANAGER}`]: true,
  [`activity:edit:${OWNER}`]: true,
  [`activity:edit:${SUPER}`]: true,

  [`activity:export:${VIEWER}`]: false,
  [`activity:export:${SALES}`]: false,
  [`activity:export:${MANAGER}`]: true,
  [`activity:export:${OWNER}`]: true,
  [`activity:export:${SUPER}`]: true,

  [`product:create:${VIEWER}`]: false,
  [`product:create:${SALES}`]: false,
  [`product:create:${MANAGER}`]: true,
  [`product:create:${OWNER}`]: true,
  [`product:create:${SUPER}`]: true,

  [`product:edit:${VIEWER}`]: false,
  [`product:edit:${SALES}`]: false,
  [`product:edit:${MANAGER}`]: true,
  [`product:edit:${OWNER}`]: true,
  [`product:edit:${SUPER}`]: true,

  [`product:archive:${VIEWER}`]: false,
  [`product:archive:${SALES}`]: false,
  [`product:archive:${MANAGER}`]: true,
  [`product:archive:${OWNER}`]: true,
  [`product:archive:${SUPER}`]: true,

  [`product:assign-sales-process:${VIEWER}`]: false,
  [`product:assign-sales-process:${SALES}`]: false,
  [`product:assign-sales-process:${MANAGER}`]: true,
  [`product:assign-sales-process:${OWNER}`]: true,
  [`product:assign-sales-process:${SUPER}`]: true,

  [`sales-process:create:${VIEWER}`]: false,
  [`sales-process:create:${SALES}`]: false,
  [`sales-process:create:${MANAGER}`]: true,
  [`sales-process:create:${OWNER}`]: true,
  [`sales-process:create:${SUPER}`]: true,

  [`sales-process:edit:${VIEWER}`]: false,
  [`sales-process:edit:${SALES}`]: false,
  [`sales-process:edit:${MANAGER}`]: true,
  [`sales-process:edit:${OWNER}`]: true,
  [`sales-process:edit:${SUPER}`]: true,

  [`tag:create:${VIEWER}`]: false,
  [`tag:create:${SALES}`]: true,
  [`tag:create:${MANAGER}`]: true,
  [`tag:create:${OWNER}`]: true,
  [`tag:create:${SUPER}`]: true,

  [`tag:edit:${VIEWER}`]: false,
  [`tag:edit:${SALES}`]: RESPONSIBLE,
  [`tag:edit:${MANAGER}`]: true,
  [`tag:edit:${OWNER}`]: true,
  [`tag:edit:${SUPER}`]: true,

  [`tag:archive:${VIEWER}`]: false,
  [`tag:archive:${SALES}`]: RESPONSIBLE,
  [`tag:archive:${MANAGER}`]: true,
  [`tag:archive:${OWNER}`]: true,
  [`tag:archive:${SUPER}`]: true,

  [`organization:general-settings:${VIEWER}`]: false,
  [`organization:general-settings:${SALES}`]: false,
  [`organization:general-settings:${MANAGER}`]: true,
  [`organization:general-settings:${OWNER}`]: true,
  [`organization:general-settings:${SUPER}`]: true,

  [`organization:custom-fields:${VIEWER}`]: false,
  [`organization:custom-fields:${SALES}`]: false,
  [`organization:custom-fields:${MANAGER}`]: true,
  [`organization:custom-fields:${OWNER}`]: true,
  [`organization:custom-fields:${SUPER}`]: true,

  [`organization:activity-stream:${VIEWER}`]: false,
  [`organization:activity-stream:${SALES}`]: false,
  [`organization:activity-stream:${MANAGER}`]: true,
  [`organization:activity-stream:${OWNER}`]: true,
  [`organization:activity-stream:${SUPER}`]: true,

  [`organization:labels:${VIEWER}`]: false,
  [`organization:labels:${SALES}`]: false,
  [`organization:labels:${MANAGER}`]: true,
  [`organization:labels:${OWNER}`]: true,
  [`organization:labels:${SUPER}`]: true,

  [`organization:users:${VIEWER}`]: false,
  [`organization:users:${SALES}`]: false,
  [`organization:users:${MANAGER}`]: true,
  [`organization:users:${OWNER}`]: true,
  [`organization:users:${SUPER}`]: true,

  [`organization:billing:${VIEWER}`]: false,
  [`organization:billing:${SALES}`]: false,
  [`organization:billing:${MANAGER}`]: true,
  [`organization:billing:${OWNER}`]: true,
  [`organization:billing:${SUPER}`]: true,
};

const buildEntityOptions = (entity) => ({
  [`${entity}:import:${SALES}`]: [false, true],

  [`${entity}:view:${VIEWER}`]: [ASSIGNED, true],
  [`${entity}:view:${SALES}`]: [ASSIGNED, true],

  [`${entity}:export:${SALES}`]: [false, true],

  [`${entity}:assign:${SALES}`]: [false, RESPONSIBLE, ASSIGNED, true],
  [`${entity}:edit:${SALES}`]: [false, RESPONSIBLE, ASSIGNED, true],
  [`${entity}:archive:${SALES}`]: [false, RESPONSIBLE, ASSIGNED, true],

  [`${entity}:log-activity:${SALES}`]: [ASSIGNED, true],
  [`${entity}:self-assign-followup:${SALES}`]: [ASSIGNED, true],
});

export const options = {
  ...buildEntityOptions("contact"),
  ...buildEntityOptions("account"),
  ...buildEntityOptions("opportunity"),
  ...buildEntityOptions("vendor"),
  [`opportunity:override-sales-process:${SALES}`]: [false, true],
  [`followup:archive:${SALES}`]: [RESPONSIBLE, ASSIGNED, true],
  [`followup:edit:${SALES}`]: [RESPONSIBLE, ASSIGNED, true],
  [`activity:edit:${SALES}`]: [RESPONSIBLE, ASSIGNED, true],
  [`activity:export:${SALES}`]: [false, true],
  [`organization:billing:${MANAGER}`]: [false, true],
  [`organization:users:${MANAGER}`]: [false, true],
};

export const validKeys = Object.keys(options);
export const validValues = [false, true, RESPONSIBLE, ASSIGNED];
