// @ts-check

/** @typedef {import("@evolved/domain").SystemField} SystemField */

import { reduceFieldsToMap } from "./reduce-fields-to-map.js";

/** @type {SystemField} */
export const account = {
  entityType: "CONTACT",
  collection: "accounts",
  dataIndex: "accountId",
  title: "Account",
  type: "SET",
  isSortable: true,
  relationship: {
    entityType: "ACCOUNT",
    cardinality: "one",
  },
  canEdit: true,
  isSystem: true,
};

/** @type {SystemField} */
export const createdOn = {
  entityType: "CONTACT",
  dataIndex: "createdOn",
  title: "Added On",
  type: "DATE",
  canEdit: false,
  isSystem: true,
};

/** @type {SystemField} */
export const email = {
  entityType: "CONTACT",
  dataIndex: "email",
  title: "Email",
  type: "TEXT",
  canEdit: true,
  canMatchEntityBy: "is_default",
  isSystem: true,
};

/** @type {SystemField} */
export const lastActedOn = {
  entityType: "CONTACT",
  dataIndex: "lastActedOn",
  title: "Last Acted On",
  type: "ACTIVITY_DATE",
  canEdit: false,
  isSystem: true,
};

/** @type {SystemField} */
export const name = {
  entityType: "CONTACT",
  dataIndex: "name",
  title: "Name",
  type: "TEXT",
  mapUiUrl: (value) => `/contacts/${value}`,
  canEdit: false,
  isSystem: true,
};

/** @type {SystemField} */
export const firstName = {
  entityType: "CONTACT",
  dataIndex: "firstName",
  title: "First Name",
  type: "TEXT",
  canEdit: true,
  canMatchEntityBy: true,
  isSystem: true,
};

/** @type {SystemField} */
export const lastName = {
  entityType: "CONTACT",
  dataIndex: "lastName",
  title: "Last Name",
  type: "TEXT",
  canEdit: true,
  canMatchEntityBy: true,
  isSystem: true,
};

/** @type {SystemField} */
export const nextFollowup = {
  entityType: "CONTACT",
  dataIndex: "nextFollowupOn",
  title: "Next Followup",
  type: "FOLLOWUP_DATE",
  canEdit: false,
  isSystem: true,
};

/** @type {SystemField} */
export const phone = {
  entityType: "CONTACT",
  dataIndex: "phone",
  title: "Phone",
  type: "TEXT",
  canEdit: true,
  isSystem: true,
};

/** @type {SystemField} */
export const tags = {
  collection: "tags",
  entityType: "CONTACT",
  dataIndex: "tags",
  title: "Tags",
  type: "SET",
  relationship: {
    entityType: "TAG",
    cardinality: "many",
  },
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const title = {
  entityType: "CONTACT",
  dataIndex: "title",
  title: "Title",
  type: "TEXT",
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const users = {
  collection: "users",
  entityType: "CONTACT",
  dataIndex: "users",
  title: "Sales Reps",
  type: "SET",
  relationship: {
    entityType: "USER",
    cardinality: "many",
  },
  isSystem: true,
  canEdit: true,
};

/** @type {SystemField} */
export const vendor = {
  collection: "vendors",
  entityType: "CONTACT",
  dataIndex: "vendorId",
  title: "Partner",
  type: "SET",
  isSortable: true,
  relationship: {
    entityType: "VENDOR",
    cardinality: "one",
  },
  isSystem: true,
  canEdit: true,
};

export const COLLECTION = Object.freeze([
  account,
  email,
  createdOn,
  lastActedOn,
  name,
  firstName,
  lastName,
  nextFollowup,
  phone,
  tags,
  users,
  title,
  vendor,
]);

export const DEFAULT_FIELDS = Object.freeze([
  "title",
  "account.name",
  "email",
  "phone",
  "lastActedOn",
  "nextFollowupOn",
]);

export const FIXED_FIELDS = Object.freeze(["name"]);

export const MAP = Object.freeze(reduceFieldsToMap(COLLECTION));
