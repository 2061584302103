// @ts-check

import { smartTrim } from "./smart-trim.js";

/**
 * Smart trim and lower case strings
 * for comparison.
 *
 * @param {string} s
 *
 * @returns {string}
 */

export const normalize = (s) => {
  return smartTrim(s).toLowerCase();
};

/**
 * @param {string} a
 * @param {string} b
 *
 * @returns {boolean}
 */
export const areHeadersEqual = (a, b) => {
  return normalize(a) === normalize(b);
};
