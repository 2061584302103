// @ts-check

import { useQueryClient } from "@tanstack/react-query";

import clone from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import produce from "immer";
import { Button, Modal } from "antd";
import { diff } from "radash";
import React, { useState } from "react";

import * as domain from "@evolved/domain";

import { getPartnersLabel } from "@evolved/labels";
import { viewTypes } from "@evolved/constants";

import getErrorMessage from "../../../utils/get-error-message";
import { ErrorMessage } from "../../../components/error-message";
import { useMe } from "../../../data/use-me";
import {
  useOrganization,
  useSetUserDefinedFields,
  root as organizationRoot,
} from "../../../data/use-organization";
import { useViewStore } from "../../../stores/view";

import { ConfigureEntityMatch } from "./configure-entity-match";

// import { ConfigureRelationshipMatch } from "./configure-relationship-match";
import { ConfigureUdfs } from "./configure-udfs";
import { FileUpload } from "./file-upload";
import { GetTemplate } from "./get-template";
// import { getDuplicateHeaders } from "./get-duplicate-headers";
import { useEntityCache } from "./use-entity-cache";
import { IdentifyHeaders } from "./identify-headers";
// import { ConfigureRowMatch } from "./configure-row-match";
import { getImportFields } from "./get-import-fields";
import { reduceState } from "./reduce-state";

/** @typedef {import("./domain").ImportableEntityTypes} Types */

/**
 * @param {Object} o
 * @param {domain.Organization} o.organization
 * @param {domain.EntityType} o.entityType
 *
 * @returns {string}
 */
const getTitle = ({ organization, entityType }) => {
  /** @type {Record<Types, string>} */
  const options = {
    ACCOUNT: "Account Import",
    CONTACT: "Contact Import",
    OPPORTUNITY: "Opportunity Import",
    VENDOR: `${getPartnersLabel(organization, "singular")} Import`,
  };

  return options[entityType];
};

/** @typedef {import("./domain").ImportField} ImportField
/** @typedef {import("./domain").ImportUserState} ImportUserState

/**
 * @typedef {Object} WizardProps
 *
 * @prop {Types} entityType
 * @prop {import("./use-entity-cache").EntityCache} entityCache
 * @prop {domain.Organization} organization
 * @prop {ImportField[]} importFields
 *
 * @prop {import("@evolved/domain").Organization} organization
 */

/** @type {ImportUserState} */
const initialTestingState = {
  "upload": {
    "headers": [
      "Name",
      "Status",
      "Contacts",
      "Last Acted On",
      "Next Followup",
      "Website",
      "Phone",
      "Address"
    ],
    "rows": [
      [
        "Foo",
        "Client",
        "joe@gmail.com, someguy@gmail.com, Brad Abel, John Mang, brad.j.abel@gmail.com",
        "Jan 28 2024",
        "Jan 22 2020",
        "",
        "Something",
        ""
      ],
      [
        "Mang",
        "Prospect",
        "John Mang, foo mah goo",
        "Sep 10 2023",
        "",
        "",
        "",
        ""
      ],
      [
        "Test",
        "Lead",
        "brad.j.abel@gmail.com",
        "",
        "",
        "",
        "",
        ""
      ],
      [
        "New",
        "Lead",
        "",
        "",
        "",
        "",
        "",
        ""
      ],
      [
        "Foo Bar",
        "Lead",
        "Stan Peake",
        "",
        "",
        "",
        "",
        ""
      ],
      [
        "foo   ",
        "Lead",
        "Stan Peake",
        "",
        "",
        "",
        "",
        ""
      ]
    ]
  },
};

/**
 * Import wizard component that guides users through the data import process
 * @param {WizardProps} props
 */
const Wizard = (props) => {
  const { entityType, entityCache, importFields, organization } = props;
  console.log(organization)
  console.log(entityCache)

  const queryClient = useQueryClient();
  const profile = useMe();

  const setUserDefinedFields = useSetUserDefinedFields();

  /** @type {domain.UseState<{value?: string}>} */
  const [error, setError] = useState({});

  /** @type {domain.UseState<ImportUserState>} */
  const [state, setState] = useState(initialTestingState);
  /** @type {domain.UseState<ImportUserState>} */
  const [previousState, setPreviousState] = useState({});

  /**
   * @param {ImportUserState} update
   */
  const updateState = (update) => {
    console.log({ update })
    setError({});

    setPreviousState({ ...state });
    setState({
      ...state,
      ...update,
    })
  };

  const onError = (error) => {
    setError({ value: error.message });
  };

  // TODO: left off using the last step if no user state is set
  // then adding in logic to allow for manual moving through
  // and to show the steps in the step and design UI

  const reducedStepStates = reduceState({ state, previousState, importFields });
  const reducedState = reducedStepStates.find((step) => {
    return step.stepId === state.stepId
  }) ?? reducedStepStates[reducedStepStates.length - 1];

  const getPreviousStepId = () => {
    const index = reducedStepStates.findIndex((stepState) => {
      return stepState.stepId === reducedState.stepId;
    });

    if (index < 0) {
      throw new Error("Should always find previous step.");
    }

    if (index === 0) {
      return reducedStepStates[0].stepId;
    }

    return reducedStepStates[index - 1].stepId;
  };

  /** @type {JSX.Element | null} */
  let renderedStep = null;

  console.log(organization);
  console.log(state);
  console.log(reducedState);
  console.log(importFields)
  if (reducedState.stepId === "upload_file") {
    renderedStep = (
      <div>
        {reducedState.errors?.length ? reducedState.errors.map((error) => {
          if (error.type === "duplicate_headers") {
            return (
              <>
                <div className="mb-2">
                  Duplicate headers found:
                </div>
                <div className="mb-2 font-semibold">
                  {error.duplicateHeaders.join(", ")}
                </div>
                <div className="mb-2">
                  Please reconcile them and submit again.
                </div>
              </>

            )
          }

          return null;
        }) : <GetTemplate
          {...{ entityType, importFields, onError }}
        />}
        <FileUpload
          onError={onError}
          onSuccess={(result) => {
            updateState({
              upload: result,
            })
          }}
        />
      </div>
    );
  }

  if (reducedState.stepId === "configure_header_data_indexes") {
    renderedStep = (
      <IdentifyHeaders
        {...{
          importFields,
          headers: reducedState.headers,
          defaults: reducedState.defaults
        }}
        headerDataIndexes={state.headerDataIndexes}
        onBack={() => {
          updateState({ upload: undefined });
        }}
        onSuccess={(headerDataIndexes) => {
          updateState({ headerDataIndexes, stepId: undefined });
        }}
      />
    )
  }

  if (reducedState.stepId === "create_user_defined_fields") {
    renderedStep = (
      <ConfigureUdfs
        {...{
          entityType,
          newUserDefinedFields: state.newUserDefinedFields,
          headerDataIndexes: reducedState.headerDataIndexes,
          upload: reducedState.upload,
          onBack: () => {
            updateState({
              stepId: getPreviousStepId(),
            });
          },
          onNext: (update) => {
            // TODO: left off here adding newUserDefinedFields
            // as state.
            //
            // Remember, if they were to be created, they
            // should get ignored from the user state.
            // Should be idempotent. test this by:
            // - store on state
            // - update on local query cache
            // - we want to ensure they wouldn't be created
            // again... reducedState... I think it's just
            // always confirm... reduced state for confirm
            // should:
            // - confirm user state against actual, so as to
            // not create the same thing again. For example,
            // creating udfs worked, but the next step failed,
            // organization updates, the newUdfDataIndexes
            // will pass because they exist on the organization
            // and in the user state, but, there's
            // nothing to reset the user state. So,
            // when we go to create the data, we want to
            // confirm...
            //
            // NOTE: I think we just double check on upload.
            // So as to not create something again. It
            // needs to be idempotent. I mean...
            // If we went to create the same udfs again,
            // should be fine.
            // If we went to create the same relationships,
            // it should be fine.
            // If we went to update again, should be fine.
            // The new items, would match, and then update,
            // should be fine.

            // so many ideas on import... can record
            // on an entity that it was updated by a
            // particular import, etc, so there's that
            // history.
            //
            // function over performance. milliseconds
            // don't matter to users that want easy UX.

            // TODO: we don't need to create right away
            // here... instead, we simply add these to
            // the state, which will be created on confirm.
            // Then on confirm we can show a loader for each.
            // Could even have a single import call that
            // handles it all in one transaction.
            //
            // But for now, at the end is fine, they
            // are all individual chunks that can happen
            // idempotently anyway.

            updateState({
              newUserDefinedFields: update,
              stepId: undefined,
            })

            // queryClient.setQueryData([organizationRoot], {
            //   ...organization,
            //   userDefinedFields: update,
            // });

            // setUserDefinedFields.mutate(update);
            console.log(update);
          }
        }}
      />
    )
  }

  if (reducedState.stepId === "configure_entity_match") {
    renderedStep = (
      <ConfigureEntityMatch {...{
        upload: reducedState.upload,
        headerDataIndexes: reducedState.headerDataIndexes,
        importFields,
        entityCache,
        entityType,
        onBack: () => {
          updateState({
            stepId: getPreviousStepId(),
          });
        },
        onNext: (config) => {
          console.log(config);
        },
      }} />
    )
  }

  // const fileUpload = (
  //   <div>
  //     <FileUpload
  //       onStart={() => {
  //         setError(null);
  //       }}
  //       onError={(error) => {
  //         console.error(error);
  //         setError(error);
  //       }}
  //       onSuccess={(result) => {
  //         setUpload(result);
  //       }}
  //     />
  //   </div>
  // );

  // if (!upload) {
  //   step = (
  //     <div className="max-w-[512px]">
  //       <GetTemplate
  //         importFieldConfigs={fieldConfigs}
  //         profile={profile}
  //         setError={setError}
  //         type={entityType}
  //         userDefinedFields={organization.userDefinedFields}
  //       />
  //       {fileUpload}
  //     </div >
  //   );
  // }
  // else if (upload && !headerConfigs) {
  //   step = (
  //     <IdentifyHeaders
  //       entityType={entityType}
  //       headers={upload.headers}
  //       fieldConfigs={fieldConfigs}
  //       onBack={() => {
  //         setUpload();
  //       }}
  //       onNext={(configs) => {
  //         console.log(configs)
  //         setHeaderConfigs(configs);
  //       }}
  //     />
  //   )
  // }
  // else if (!rowMatchConfig) {
  //   return (
  //     <ConfigureRowMatch
  //       entityType={entityType}
  //       headerConfigs={headerConfigs}
  //       onBack={() => {
  //         setHeaderConfigs();
  //       }}
  //       onNext={(config) => {
  //         setRowMatchConfig(config);
  //       }}
  //     />
  //   )
  // }
  // else if (!relationshipMatchConfig) {
  //
  // }
  // else if (isMissingIdentifyingHeader({ entityType, headers: upload.headers })) {
  //   const identifyingHeaders = identifyingFields[entityType].map(({ title }) => title).join(" or ");
  //
  //   step = (
  //     <>
  //       {identifyingFields[entityType].length > 1 ? (
  //         <div className="mb-2">
  //           At least one of <strong>{identifyingHeaders}</strong> required to identify data in CRM.
  //         </div>
  //       ) : (
  //         <div className="mb-2">
  //           Header <strong>{headers}</strong> required to identify data in CRM.
  //         </div>
  //       )}
  //       {fileUpload}
  //     </>
  //   )
  // }
  // else if (duplicateHeaders?.length > 0) {
  //   step = (
  //     <>
  //       <div className="mb-2">
  //         Duplicate headers found. Please reconcile them and submit again:
  //       </div>
  //       <div className="mb-2 font-semibold">
  //         {duplicateHeaders.join(", ")}
  //       </div>
  //       {fileUpload}
  //     </>
  //   )
  // }
  // else if (missingNameRows) {
  //   const identifyingHeaders = identifyingFields[entityType].map(({ title }) => title).join(" or ");
  //
  //   step = (
  //     <>
  //       {identifyingFields[entityType].length > 1 ? (
  //         <div className="mb-2">
  //           Some rows are missing a value in at least one of <strong>{identifyingHeaders}</strong> required to identify data in CRM:
  //         </div>
  //       ) : (
  //         <div className="mb-2">
  //           Some rows are missing a <strong>{identifyingHeaders}</strong> value required to identify data in CRM:
  //         </div>
  //       )}
  //       <div className="mb-2"><span className="text-lg font-bold">{missingNameRows.map(row => Number(row) + 1).join(", ")}</span></div>
  //       <div className="mb-2">Please add the missing data and re-upload the data.</div>
  //       {fileUpload}
  //     </>
  //   )
  // }
  // else if (mustReconcileMultipleRowMatches) {
  //   const identifyingHeaders = identifyingFields[entityType].map(({ title }) => title).join(" or ");
  //
  //   console.log(rowMatches);
  //
  //   step = (
  //     <div>
  //       {identifyingFields[entityType].length > 1 ? (
  //         <div className="mb-2">
  //           Some rows matched to multiple entries in the system. Rows are matched by at least one of the fields <strong>{identifyingHeaders}</strong>, used in that order:
  //         </div>
  //       ) : (
  //         <div className="mb-2">
  //           Some rows matched to multiple entries in the system. Rows are matched by the <strong>{identifyingHeaders} field</strong>:
  //         </div>
  //       )}
  //       <div className="my-8">{rowMatches.filter(({ multipleMatches }) => !!multipleMatches).map(({ multipleMatches }, index) => (
  //         <div className="mb-2">Row <strong>{Number(index + 1)}</strong> matched {multipleMatches.length} records on {multipleMatches[0].header} <strong>{multipleMatches[0].label}</strong></div>
  //       ))}</div>
  //       <div className="mb-4">You can either delete or merge the duplicates so there is only one record for each value and re-upload again, or continue and these rows will be ignored on this import.</div>
  //       <div className="flex justify-end">
  //         <Button type="primary" onClick={() => {
  //           setIgnoreDuplicateMatches(true);
  //         }}
  //         >Continue and Ignore These Rows</Button>
  //       </div>
  //     </div>
  //   )
  // }
  // else if (mustReconcileMatchKey) {
  //   // contacts for example, okay so we can
  //
  //   const identifyingHeaders = identifyingFields[entityType].map(({ title }) => title).join(" or ");
  //
  //   console.log(rowMatches);
  //
  //   step = (
  //     <div>
  //       {identifyingFields[entityType].length > 1 ? (
  //         <div className="mb-2">
  //           Some rows matched to multiple entries in the system. Rows are matched by at least one of the fields <strong>{identifyingHeaders}</strong>, used in that order:
  //         </div>
  //       ) : (
  //         <div className="mb-2">
  //           Some rows matched to multiple entries in the system. Rows are matched by the <strong>{identifyingHeaders} field</strong>:
  //         </div>
  //       )}
  //       <div className="my-8">{rowMatches.filter(({ multipleMatches }) => !!multipleMatches).map(({ multipleMatches }, index) => (
  //         <div className="mb-2">Row <strong>{Number(index + 1)}</strong> matched {multipleMatches.length} records on {multipleMatches[0].header} <strong>{multipleMatches[0].label}</strong></div>
  //       ))}</div>
  //       <div className="mb-4">You can either delete or merge the duplicates so there is only one record for each value and re-upload again, or continue and these rows will be ignored on this import.</div>
  //       <div className="flex justify-end">
  //         <Button type="primary" onClick={() => {
  //           setIgnoreDuplicateMatches(true);
  //         }}
  //         >Continue and Ignore These Rows</Button>
  //       </div>
  //     </div>
  //   )
  //
  //
  //   return <div>{mustReconcileMatchKey}</div>
  // }
  // else if (!isEmpty(newUserDefinedFields)) {
  //   step = (
  //     <div>
  //       <CreateUdfs
  //         loading={
  //           setUserDefinedFields.isLoading
  //         }
  //         entityType={entityType}
  //         newUdfHeaders={newUserDefinedFields}
  //         onBack={() => {
  //           setUpload();
  //         }}
  //         onNext={({ removed, newUdfs } = {}) => {
  //           if (newUdfs.length > 0) {
  //             // NOTE: intentional mutation without re-render
  //             upload.headers = diff(upload.headers, removed);
  //
  //             // NOTE: this will:
  //             // - update the organization user defined fields
  //             // - re-render this component and re-calc newUserDefinedFields
  //             // which should now be empty, and get past this step.
  //             // TODO: test error handling
  //             // TODO: make it clear that anything selected will be created right away
  //             setUserDefinedFields.mutate([
  //               ...organization.userDefinedFields ?? [],
  //               ...newUdfs,
  //             ]);
  //           } else {
  //             setUpload({ ...upload, headers: diff(upload.headers, removed) })
  //           }
  //         }}
  //         rows={upload.rows}
  //       />
  //     </div>
  //   );
  // }
  // else if () {
  //
  // }
  // else if (unmatchedRelationships) {
  //   // TODO: review relationships 
  //   step = (
  //     <div>Unmatched Relationships</div>
  //   )
  // } else {
  //   // TODO: review table
  //   step = (
  //     <div>Done</div>
  //   );
  // }

  console.log(error);
  return (
    <div className="w-full">
      {renderedStep}
      {error.value && (
        <ErrorMessage
          action={null}
          refresh={null}
          error={`${getErrorMessage(
            error.value
          )}. Take any corrective actions and re-upload the file to try again.`}
          style={{ marginTop: "24px" }}
          support={false}
        />
      )}
    </div>
  );
};


/**
 * @param {{
 *  isOpen: boolean;
 *  type: Types;
 *  close: () => void;
 * }} props
 */
export const ImportDataModal = (props) => {
  const { type: entityType } = props;

  /** @type {domain.Organization} */
  const organization = useOrganization();
  // const profile = useMe();

  const entityCache = useEntityCache();
  const importFields = getImportFields({
    entityType,
    userDefinedFields: organization.userDefinedFields,
  });

  const importField = importFields[0];
  importField.name

  return (
    <Modal
      destroyOnClose
      footer={null}
      maskClosable={false}
      onCancel={props.close}
      open={props.isOpen}
      title={getTitle({ entityType, organization })}
    >
      <div className="w-[100%] flex justify-center">
        <Wizard
          {...{ entityType, entityCache, organization, importFields }}
        />
      </div>
    </Modal>
  )


  // const setUserDefinedFields = useSetUserDefinedFields({
  //   onSuccess: (data, {mutation}) => mutation.onFinal.skip(),
  // });
  //
  // const createAccountBatch = useCreateAccountBatch();
  // const createContactBatch = useCreateContactBatch();
  // const createOpportunityBatch = useCreateOpportunityBatch();
  // const createProductBatch = useCreateProductBatch();
  // const createVendorBatch = useCreateVendorBatch();

  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState();
  // const [fileList, setFileList, clearFileList] = useFileListState([]);
  // const [payload, setPayload] = useState();

  // const IMPORTS = {
  //   [ACCOUNT]: createAccountBatch.mutateAsync,
  //   [CONTACT]: createContactBatch.mutateAsync,
  //   [OPPORTUNITY]: createOpportunityBatch.mutateAsync,
  //   [VENDOR]: createVendorBatch.mutateAsync,
  // };

  // const onOk = async () => {
  //   const temporaryIdIndexMap = {
  //     accounts: "accountId",
  //     products: "productId",
  //     salesProcesses: "salesProcessId",
  //     vendors: "vendorId",
  //   };
  //
  //   setLoading(true);
  //
  //   try {
  //     if (!isEmpty(payload.udfs)) {
  //       const udfPayload = payload.udfs.reduce((acc, udf) => {
  //         const existingIndex = acc.findIndex(({ id }) => id === udf.id);
  //
  //         if (existingIndex > -1) {
  //           acc[existingIndex] = udf;
  //         } else {
  //           acc.push(udf);
  //         }
  //
  //         return acc;
  //       }, clone(organization.userDefinedFields || []));
  //
  //       await setUserDefinedFields.mutateAsync(udfPayload);
  //
  //       payload.udfs.forEach((u) =>
  //         useViewStore.getState().addField(type)(`userDefinedFields.${u.id}`)
  //       );
  //     }
  //
  //     let entitiesPayload = payload.entities;
  //
  //     if (!isEmpty(payload.relationships)) {
  //       for (const relationship of payload.relationships) {
  //         const { type, entities } = relationship;
  //
  //         // TODO: so what are the things that we want to import?
  //         // anything that we can create must be created with just a name, right?
  //         const importType = {
  //           accounts: createAccountBatch.mutateAsync,
  //           products: createProductBatch.mutateAsync,
  //           vendors: createVendorBatch.mutateAsync,
  //         }[type];
  //         // TODO: should show the user what entities will be created, could even
  //         // bring them through the process for products, although for now, we can default
  //         // that's fine. Just strip those things off and explain it to the user.
  //
  //         // The other good enough thing for now, is that we stick with not uploading these other things, because it works today, and instead,
  //
  //         const result = await importType(map(entities, ({ value }) => value));
  //
  //         entitiesPayload = produce(payload.entities, (draft) => {
  //           for (const r of result) {
  //             const relationship = entities[normalizeText(r.name)];
  //
  //             if (relationship) {
  //               for (const i of relationship.indexes) {
  //                 draft[i][temporaryIdIndexMap[type]] = r._id;
  //               }
  //             }
  //           }
  //         });
  //       }
  //     }
  //
  //     await IMPORTS[type](entitiesPayload);
  //     setLoading(false);
  //
  //     close();
  //   } catch (err) {
  //     clearFileList();
  //     setError(err);
  //     setLoading(false);
  //   }
  // };

  // const userDefinedFields = get(organization, "userDefinedFields", []).filter(
  //   (field) => field.type === type
  // );

  // now that I have the headers, what do they match to?

  // it's a pretty long process tbh... may deserve an entire page

  // return (
  //   <Modal
  //     footer={null}
  //     maskClosable={false}
  //     open={isOpen}
  //     title={getTitle(organization)(type)}
  //     okButtonProps={{ disabled: isEmpty(fileList) || error }}
  //   >
  //     {isOpen && fieldConfigs && !payload && (
  //       <div style={style.container}>
  //         {!loading ? (
  //           <>
  //             <GetTemplate
  //               profile={profile}
  //               userDefinedFields={userDefinedFields}
  //               setError={setError}
  //               type={type}
  //             />
  //             <FileUpload
  //               onStart={() => {
  //                 setError();
  //               }}
  //               onError={(error) => {
  //                 console.error(error);
  //                 setError(error);
  //               }}
  //               onSuccess={(result) => {
  //                 console.log("success");
  //                 console.log(result);
  //               }}
  //             />
  //           </>
  //         ) : (
  //           <div
  //             style={{
  //               alignItems: "center",
  //               flexDirection: "column",
  //               display: "flex",
  //               height: "352px",
  //               justifyContent: "center",
  //             }}
  //           >
  //             <h2>We are working hard to load your data!</h2>
  //             <h3>
  //               {payload?.entities?.length} {type.toLowerCase()}s total...
  //             </h3>
  //           </div>
  //         )}
  //         {error && (
  //           <ErrorMessage
  //             error={`${getErrorMessage(
  //               error
  //             )}. Take any corrective actions and re-upload the file to try again.`}
  //             style={{ marginTop: "24px" }}
  //             support={false}
  //           />
  //         )}
  //       </div>
  //     )}
  //     {payload && <PreviewTable payload={payload} />}
  //   </Modal>
  // );
};
