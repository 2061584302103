// @ts-check

import { includes } from "./refine.js";

/**
 * @typedef {import('./entity').EntityType} EntityType
 */

export const FIELD_TYPE = Object.freeze({
  ACCOUNT_STATE: "ACCOUNT_STATE",
  ACTIVITY_DATE: "ACTIVITY_DATE",
  CALCULATED: "CALCULATED",
  DATE: "DATE",
  DOLLAR: "DOLLAR",
  FOLLOWUP_DATE: "FOLLOWUP_DATE",
  LINK: "LINK",
  NUMBER: "NUMBER",
  PERCENT: "PERCENT",
  RANGE: "RANGE",
  SELECT: "SELECT",
  SET: "SET",
  TEXT: "TEXT",
});
/**
 * @typedef {keyof typeof FIELD_TYPE} FieldType
 */

/** @satisfies {Readonly<FieldType[]>} */
export const SORTABLE_FIELD_TYPES = Object.freeze([
  "ACCOUNT_STATE",
  "ACTIVITY_DATE",
  "CALCULATED",
  "DATE",
  "DOLLAR",
  "FOLLOWUP_DATE",
  "NUMBER",
  "PERCENT",
  "SELECT",
  "SET",
  "TEXT",
]);
/**
 * @typedef {typeof SORTABLE_FIELD_TYPES[number]} SortableFieldType
 */

/**
 * @typedef {{
 *  dataIndex: string;
 *  title: string;
 *  entityType: EntityType;
 *  canEdit: boolean;
 *  isRequired?: boolean;
 *  isSystem: true;
 * }} SystemFieldBase
 */

// NOTE:
// - canMatchEntityBy = 'is_priority' means if
// that field is provided in an import it IS what
// is used to match.
/**
 * @typedef {(
 *  | {
 *      type: "TEXT";
 *      canMatchEntityBy?: true | 'is_default' | 'is_priority';
 *      mapUiUrl?: (id: string) => string;
 *    }
 *  | {
 *      type: "LINK";
 *      canMatchEntityBy?: true | 'is_default' | 'is_priority';
 *    }
 *  | {
 *      type: "SET";
 *      collection: string;
 *      isSortable?: boolean;
 *      relationship: {
 *        entityType: EntityType;
 *        cardinality: "one" | "many";
 *      };
 *    }
 *  | {
 *      type: "SELECT";
 *      options: {
 *        id: string;
 *        label: string;
 *        isArchived?: boolean;
 *      }[];
 *    }
 *  | {
 *      type: "CALCULATED";
 *      formula: string[];
 *    }
 *  | {
 *      type: Exclude<FieldType, "SET" | "TEXT" | "SELECT" | "LINK" | "CALCULATED">;
 *    }
 * )} FieldTypeConfigs
 */

/**
 * @typedef {Readonly<SystemFieldBase & FieldTypeConfigs>} SystemField
 */

/**
 * @typedef {{
 *  id: string;
 *  description?: string;
 *  hideFromDetailsPage?: boolean;
 *  name: string;
 *  type: EntityType;
 * }} UserDefinedFieldBase
 */

/** @satisfies {Readonly<FieldType[]>} */
export const USER_DEFINED_FIELD_TYPES = Object.freeze([
  "CALCULATED",
  "SELECT",
  "TEXT",
  "DATE",
  "NUMBER",
]);
/**
 * @typedef {typeof USER_DEFINED_FIELD_TYPES[number]} UserDefinedFieldType
 */

/**
 * @typedef {Readonly<UserDefinedFieldBase & (
 * | {
 *      dataType: "CALCULATED";
 *      formula: string[];
 *   }
 * | {
 *  dataType: "SELECT";
 *  options: {
 *    id: string;
 *    label: string;
 *    isArchived?: boolean;
 *  }[];
 * } | {
 *  dataType: Extract<FieldType, "TEXT" | "DATE" | "NUMBER">;
 * })>} UserDefinedField
 */

/**
 * @param {UserDefinedField | SystemField} field
 * @returns {field is SystemField}
 */
export const isSystemField = (field) => {
  return "isSystem" in field;
};

/**
 * @param {UserDefinedField | SystemField} field
 */
export const isFieldSortable = (field) => {
  if ("isSystem" in field) {
    if (!includes(SORTABLE_FIELD_TYPES, field.type)) {
      return false;
    }

    if (field.type === "SET" && !field.isSortable) {
      return false;
    }
  }

  // NOTE: If not 'isSystem' then it is
  // a UserDefinedField. All UserDefinedField
  // types are sortable. This will have to
  // change if they are not.
  return true;
};
