import find from "lodash/find";
import get from "lodash/get";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isFinite from "lodash/isFinite";
import isPlainObject from "lodash/isPlainObject";
import isNil from "lodash/isNil";
import map from "lodash/map";
import reduce from "lodash/reduce";
import Parse from "papaparse";

import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { saveAs } from "file-saver";

import { accountStates, fieldTypes, viewTypes } from "@evolved/constants";
import { calculateRow, displayDay } from "@evolved/views";
import { getPartnersLabel } from "@evolved/labels";

import { useOrganization } from "data/use-organization";
import { long } from "utils/format-date";
import { useAccounts } from "../../../data/use-accounts";
import { useActivityTypes } from "../../../data/use-activity-types";
import { useContacts } from "../../../data/use-contacts";
import { useLossReasons } from "../../../data/use-loss-reasons";
import { useOpportunities } from "../../../data/use-opportunities";
import { useOpportunityStates } from "../../../data/use-opportunity-states";
import { useProducts } from "../../../data/use-products";
import { useSalesProcesses } from "../../../data/use-sales-processes";
import { useTags } from "../../../data/use-tags";
import { useUsers } from "../../../data/use-users";
import { useVendors } from "../../../data/use-vendors";
import { useViewStore } from "../../../stores/view";
import { useFieldConfigs } from "../use-field-configs";
import { withPermission } from "../../with-permission";
import { styles } from "@evolved/constants";

const {
  ACCOUNT_STATE,
  ACTIVITY_DATE,
  CALCULATED,
  DATE,
  DOLLAR,
  FOLLOWUP_DATE,
  LINK,
  NUMBER,
  PERCENT,
  SELECT,
  SET,
  TEXT,
} = fieldTypes;

const { ACCOUNT, ACTIVITY, CONTACT, OPPORTUNITY, VENDOR } = viewTypes;

const { display: displayAccountState } = accountStates;

const FORMATTER = {
  [ACCOUNT_STATE]: displayAccountState,
  [ACTIVITY_DATE]: (value) => (value ? long(value) : ""),
  [CALCULATED]: (_value, config, _cache, row) => {
    const result = calculateRow(row, config);
    return result === null ? "" : result;
  },
  [DATE]: (value) => (value ? displayDay(value) : ""),
  [DOLLAR]: (value) => (!isNil(value) ? value : ""),
  [FOLLOWUP_DATE]: (value) => (value ? long(value) : ""),
  [LINK]: (value) => value || "",
  [NUMBER]: (value) => (isFinite(Number(value)) ? Number(value) : ""),
  [PERCENT]: (value) => (!isNil(value) ? value : ""),
  [SELECT]: (value, config) => {
    const { options } = config;

    return (
      find(options, ({ id, isArchived }) => id === value && !isArchived) || {
        label: "",
      }
    ).label;
  },
  [SET]: (value, config, labels) => {
    value = isArray(value) ? value : [value];

    if (isEmpty(value)) {
      return "";
    }

    return value
      .reduce((accumulator, id) => {
        const { data, label } = labels[config.collection];

        const entity = data.byId(isPlainObject(id) ? id._id : id);

        if (!entity) {
          return accumulator;
        }

        return [...accumulator, entity[label]];
      }, [])
      .join(", ");
  },
  [TEXT]: (value) => value || "",
};

const getFilename = (organization) => (type) =>
  ({
    [ACCOUNT]: "accounts",
    [ACTIVITY]: "activities",
    [CONTACT]: "contacts",
    [OPPORTUNITY]: "opportunities",
    [VENDOR]: getPartnersLabel(organization),
  }[type]);

const onClick =
  ({ fields, fieldConfigs, labels, organization, data, viewType, withId }) =>
  () => {
    const formattedData = map(data, (row) => {
      return reduce(
        fields,
        (formatted, dataIndex) => {
          const config = fieldConfigs[dataIndex];

          if (isNil(config)) return formatted;

          const { title, type } = config;
          let value = get(row, dataIndex);

          if (isNil(value) && type !== CALCULATED) {
            return formatted;
          }

          return {
            ...formatted,
            [title]: FORMATTER[type](value, config, labels, row),
          };
        },
        {
          ...(withId ? { ID: row._id } : {}),
        }
      );
    });

    const blob = new Blob(
      [
        Parse.unparse(formattedData, {
          header: true,
          columns: [
            ...(withId ? ["ID"] : []),
            ...fields
              .filter((dataIndex) => fieldConfigs[dataIndex])
              .map((dataIndex) => fieldConfigs[dataIndex].title),
          ],
        }),
      ],
      {
        type: "text/plain;charset=utf-8",
      }
    );

    saveAs(blob, `${getFilename(organization)(viewType) || "data"}.csv`);
  };

export const DownloadCSV = withPermission(
  ({ viewType }) => `${viewType.toLowerCase()}:export`
)(
  ({
    data,
    viewType,
    asText,
    withId,
    fieldsOverride,
    fieldConfigsOverride,
  }) => {
    const fields =
      fieldsOverride || useViewStore((state) => state[viewType].fields);
    const fieldConfigs = fieldConfigsOverride ?? useFieldConfigs(viewType);

    const organization = useOrganization();
    const accounts = useAccounts();
    const activityTypes = useActivityTypes();
    const contacts = useContacts();
    const lossReasons = useLossReasons();
    const opportunities = useOpportunities();
    const opportnityStates = useOpportunityStates();
    const products = useProducts();
    const salesProcesses = useSalesProcesses();
    const tags = useTags();
    const users = useUsers();
    const vendors = useVendors();

    const labels = {
      accounts: { data: accounts, label: "name" },
      activityTypes: { data: activityTypes, label: "label" },
      contacts: { data: contacts, label: "alias" },
      lossReasons: { data: lossReasons, label: "label" },
      opportunities: { data: opportunities, label: "alias" },
      opportunityStates: { data: opportnityStates, label: "label" },
      products: { data: products, label: "name" },
      salesProcesses: { data: salesProcesses, label: "name" },
      tags: { data: tags, label: "label" },
      users: { data: users, label: "alias" },
      vendors: { data: vendors, label: "name" },
    };

    data =
      data ??
      {
        [ACCOUNT]: accounts.all({ includeArchived: false }),
        [ACTIVITY]: [],
        [CONTACT]: contacts.all({ includeArchived: false }),
        [OPPORTUNITY]: opportunities.all({ includeArchived: false }),
        [VENDOR]: vendors.all({ includeArchived: false }),
      }[viewType];

    if (asText) {
      return (
        <span
          className="font-bold"
          style={styles.CLICKABLE_STYLE}
          onClick={onClick({
            data,
            fieldConfigs,
            fields,
            labels,
            organization,
            viewType,
            withId,
          })}
        >
          {asText}
        </span>
      );
    }

    return (
      <Button
        ghost
        icon={<DownloadOutlined />}
        onClick={onClick({
          data,
          fieldConfigs,
          fields,
          labels,
          organization,
          viewType,
          withId,
        })}
        type="primary"
      >
        Export
      </Button>
    );
  }
);
