// @ts-check

import React, { useState } from "react";
import { Button, Select } from "antd";
import {
  ArrowRightOutlined
} from "@ant-design/icons";

/**
 * @callback OnSuccess
 * 
 * @param {(string | null)[]} headerDataIndexes 
 */

export const CREATE_USER_DEFINED_FIELD_OPTION = Object.freeze({
  label: "Create User Defined Field",
  value: "create_user_defined_field",
});

/** @type {React.FC<{
 *  defaults: (string | null)[];
 *  headers: (string | null)[];
 *  importFields: import("./import-data").ImportField[];
 *  headerDataIndexes?: (string | null)[];
 *  onBack: () => void;
 *  onSuccess: OnSuccess;
 * }>} */
export const IdentifyHeaders = (props) => {
  const { headers, defaults, importFields, headerDataIndexes, onSuccess, onBack } = props;

  /**
    * @type {import("@evolved/domain").UseState<(string | null)[]>}
    */
  const [configs, setConfigs] = useState(
    headerDataIndexes ?? defaults,
  );

  /**
   * @param {number} index
   * 
   * @returns {{value: string; label: string;}[]}
   */
  const getAvailableOptions = (index) => {
    return [
      CREATE_USER_DEFINED_FIELD_OPTION,
      ...importFields.filter(({ dataIndex }) => {
        return !configs.some((existing, i) => index !== i && existing === dataIndex);
      }).map(({ dataIndex, name }) => ({
        value: dataIndex,
        label: name,
      })),
    ]
  }

  return (
    <div className="w-full">
      <div className={"flex w-full justify-between mb-4"}>
        <div className="w-[50%] font-bold">
          CSV Header</div>
        <div className="w-[50%] font-bold">
          CRM Field
        </div>
      </div>
      {headers.map((header, index) => {
        const dataIndex = configs[index];

        return (
          <div className={"flex w-full justify-between mb-2"} key={header}>
            <div className="w-[50%]">{header}</div>
            <div className="w-[50%] flex">
              <ArrowRightOutlined className="mr-4" />
              <Select
                allowClear
                onClear={() => {
                  const nextConfigs = [...configs];

                  nextConfigs.splice(index, 1, null)

                  setConfigs(nextConfigs)
                }}
                placeholder="skip"
                className="grow"
                value={dataIndex}
                onChange={(dataIndex) => {
                  const nextConfigs = [...configs];
                  nextConfigs.splice(index, 1, dataIndex)

                  setConfigs(nextConfigs)
                }}
                options={getAvailableOptions(index)}
              />
            </div>
          </div>
        );
      })}
      <div className="flex justify-end">
        <Button className="mr-2" onClick={onBack}>
          Back
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onSuccess(configs);
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
